import React, { useState, useEffect } from 'react'
import { generatePublicUrl } from '../../urlConfig'
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirm from './../../components/BurgerMenu/ModalConfirm';
import { useDispatch } from 'react-redux';
import { deleteOrderProductById } from '../../actions';
import ContainerModalConfirmPlusIcon from './../Orders/ContainerModalConfirmPlusIcon';

const RenderProductOrder = ({
    handleShowSettingModal,
    setClicked,
    orderItem,
    orderId,
    itemObj,
    item,
}) => {


    const [completelyFilled, setCompletelyFilled] = useState(false)
    const [showDeletingProduct, setShowDeletingProduct] = useState(false)


    const dispatch = useDispatch()

    useEffect(() => {
        if (itemObj.height !== undefined
            && itemObj.height !== undefined
            && itemObj.width !== undefined
            && itemObj.bayerPrice !== undefined
            && itemObj.typeProductColor !== undefined
        ) {
            setCompletelyFilled(true)
        } else {
            setCompletelyFilled(false)
        }
    }, [])

    const [showEditInput, setShowEditInput] = useState(false)

    const handleShow = () => setShowEditInput(!showEditInput)


    const confirmDelete = (id) => {
        const payload = {
            productOrderDetailsId: item._id,
            orderId: orderId,
            fullNameProduct: item.fullName !== "" ? item.fullName : "Не указано"
        }

        dispatch(deleteOrderProductById(payload))
        setClicked(null)
    }
    return (
        <>
            <div className={`renderProductOrder ${completelyFilled && "completelyFilled"}`} key={item._id}
                onClick={e => e.stopPropagation()}
            >
                <div className="renderProductOrder__row">
                    <div className='renderProductOrder__imgBox'>
                        <img src={generatePublicUrl(item.productPictures[0].img)} alt={item.name} />
                    </div>
                    <div className="renderProductOrder__name">
                        <a href='#'>
                            {item.fullName !== "" ? item.fullName : "Не указано"}
                        </a>
                        <SettingIconBlock
                            tooltip={"Цена товара"}
                            children={<div className="renderProductOrder__visibilityPrice">
                                {item.visibilityPrice === "admin" || item.visibilityPrice === "public" ? item.price : "Нет цены"}
                            </div>}

                        />

                    </div>


                    <div className="renderProductOrder__quantity" onClick={handleShow}>
                        <span className="renderProductOrder__quantityTitle">Кол-во</span>
                        <div className="renderProductOrder__quantityValue">{itemObj.purchasedQty}</div>
                    </div>
                    <div className="renderProductOrder__price">
                        <span className="renderProductOrder__priceTitle">Цена</span>
                        {itemObj.bayerPrice > 0
                            ? <div className="renderProductOrder__priceValue">
                                {itemObj.bayerPrice} $
                            </div>
                            : <div className="renderProductOrder__noPriceValue">
                                Не указано
                            </div>
                        }
                    </div>
                </div>

                <SettingIconBlock
                    tooltip="Подробная настройка"
                    right="5px"
                    onClick={() => handleShowSettingModal(itemObj)}
                />
                {orderItem.disableFunctionality ? null : <ContainerModalConfirmPlusIcon
                    tooltip="Удалить товар с заказа"
                    right="25px"
                    icon={<DeleteIcon />}
                    onClickShow={() => setShowDeletingProduct(true)}
                    title={`Подтвердить удаление товара с заказа ${item.fullName !== "" ? item.fullName : "Не указано"}`}
                    description="Если вы продолжите то вернуть его будет невозможно!!!"
                    show={showDeletingProduct}
                    handleClose={() => setShowDeletingProduct(false)}
                    onClick={() => confirmDelete(item._id)}
                />}
            </div>
        </>
    )
}

export default RenderProductOrder
