

import React, { useEffect, useState } from 'react'
import Input from '../../components/UI/Input/index';
import Modal from '../../components/UI/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { addProduct, updateProductById } from '../../actions';
import { Row, Col, Form } from 'react-bootstrap';
import { createCategoryList } from './../ComponentContainer/createCategoryList';
import BRenderAddPictures from '../../components/Button/BRenderAddPictures';
import { Button } from '@material-ui/core';


export const AddProductModal = ({
    slug,
    show,
    handleClose,
    currentId,
    productForm,
    setProductForm,
    setProductPictures,
    productPictures
}) => {
    const category = useSelector((state) => state.category)
    const product = useSelector((state) => currentId ? state.product.products.find((m) => m._id === currentId) : null)

    const [materialArray, setMaterialArray] = useState("");
    const [errorText, setErrorText] = useState(true);
    const [visibleAllInput, setVisibleAllInput] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (productForm.name === ""
            || productForm.quantity === ""
            || productForm.categoryId === ""
            || productForm.visibleProduct === ""
            || productForm.visibleWatermark === ""
            || productPictures.length === 0
        ) {
            setErrorText(true)
        } else {
            setErrorText(false)
        }
    }, [productForm, productPictures])

    useEffect(() => {
        if (product) setProductForm(product)
    }, [product])

    const createCategoryListTwo = (categories, options = []) => {
        const map = new Map(categories.map(cat => [cat._id, cat]))
        for (let [id, cat] of map) {
            options.push({
                value: id,
                name: cat.name
            });
            if (cat.children.length > 0) {
                createCategoryListTwo(cat.children, options);
            }
        }
        return options;
    };




    const submitProductForm = () => {
        if (currentId) {
            dispatch(updateProductById(currentId, productForm, slug))
            handleClose()
        } else {
            const form = new FormData();
            form.append("name", productForm.name);
            form.append("visibleProduct", productForm.visibleProduct);
            form.append("visibleWatermark", productForm.visibleWatermark);
            form.append("height", productForm.height);
            form.append("width", productForm.width);
            form.append("length", productForm.length);
            form.append("nameMaterial", productForm.nameMaterial);
            form.append("numberMaterialName", productForm.numberMaterialName);
            form.append("categoryOnlyLatterName", productForm.categoryOnlyLatterName);
            form.append("quantity", productForm.quantity);
            form.append("price", productForm.price);
            form.append("description", productForm.description);
            form.append("descriptionNew", productForm.descriptionNew);
            form.append("category", productForm.categoryId);
            form.append("size", productForm.size);
            form.append("categoryName", productForm.categoryName);
            form.append("categorySlug", productForm.categorySlug);
            form.append("weight", productForm.weight);
            for (let i = 0; i < materialArray.length; i++) {
                form.append("materialArray", materialArray[i]);
            }
            for (let i = 0; i < productPictures.length; i++) {
                form.append("productPictures", productPictures[i]);
            }
            dispatch(addProduct(form, slug)).then(() => handleClose());
        }
    };

    const categoryList = createCategoryList(category.categories);
    // const materialList = createMaterialList(material)

    return (
        <Modal
            disabledSubmit={currentId ? false : errorText}
            show={show}
            currentId={currentId}
            handleClose={handleClose}
            modalTitle={!currentId ? "Добавить новый продукт" : `Изменить текст в товаре- ${product.fullName}`}
            onSubmit={submitProductForm}
        >
            {/* <select
                className="form-control"
                name="categoryId"
                value={productForm.categoryId}
                onChange={(e) => handleMaterialArray(e)}
            >
                <option>Выбрать материалы</option>
                {materialList.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select> */}
            {
                visibleAllInput
                    ? <Row>
                        <Col md="8">
                            <Input
                                visibleInput={visibleAllInput}
                                label="Полное имя"
                                name="fullName"
                                value={productForm.fullName}
                                placeholder={`Введите полное имя`}
                                onChange={(e) => setProductForm({ ...productForm, fullName: e.target.value })}
                            />
                        </Col>

                    </Row>
                    : null
            }
            {
                visibleAllInput
                    ? <Row>
                        <Col md="4">
                            <Input
                                label="Сгенерированое имя по категории"
                                name="categoryOnlyLatterName"
                                value={productForm.categoryOnlyLatterName}
                                placeholder={`Часть имени`}
                                onChange={(e) => setProductForm({ ...productForm, categoryOnlyLatterName: e.target.value })}
                            />
                        </Col>
                    </Row>
                    : null
            }
            {
                visibleAllInput
                    ? <Row>
                        <Col md="4">
                            <Input
                                label="Высота( в мм.)"
                                name="height"
                                value={productForm.height}
                                placeholder={`Высота`}
                                onChange={(e) => setProductForm({ ...productForm, height: e.target.value })}
                            />
                        </Col>
                        <Col md="4">
                            <Input
                                label="Ширина( в мм.)"
                                name="width"
                                value={productForm.width}
                                placeholder={`Ширина`}
                                onChange={(e) => setProductForm({ ...productForm, width: e.target.value })}
                            />
                        </Col>
                        <Col md="4">
                            <Input
                                label="Длина ( в мм.)"
                                name="length"
                                value={productForm.length}
                                placeholder={`Длина`}
                                onChange={(e) => setProductForm({ ...productForm, length: e.target.value })}
                            />
                        </Col>
                    </Row>
                    : null
            }
            <Row>
                <Col md="8">
                    <Input
                        showError
                        label="Название"
                        name="name"
                        value={productForm.name}
                        placeholder={`Название`}
                        onChange={(e) => setProductForm({ ...productForm, name: e.target.value })}
                    />
                </Col>
                <Col md="4">
                    <Input
                        showError
                        label="Количество"
                        name="quantity"
                        value={productForm.quantity}
                        placeholder={`Количество`}
                        onChange={(e) => setProductForm({ ...productForm, quantity: e.target.value })}
                    />
                </Col>

            </Row>
            {
                visibleAllInput
                    ? <Row>
                        <Col md="6">
                            <Input
                                label="Имя по материалам"
                                name="nameMaterial"
                                value={productForm.nameMaterial}
                                placeholder="ДМ, ДБ и тд"
                                onChange={(e) => setProductForm({ ...productForm, nameMaterial: e.target.value })}
                            />
                        </Col>
                        <Col md="6">
                            <Input
                                label="Номер товара по категориям"
                                name="numberMaterialName"
                                value={productForm.numberMaterialName}
                                placeholder="Номер"
                                onChange={(e) => setProductForm({ ...productForm, numberMaterialName: e.target.value })}
                            />

                        </Col>
                    </Row>
                    : null
            }
            {
                visibleAllInput
                    ? <Row>
                        <Col md="8">
                            <Input
                                label="В какой категории находится"
                                name="categoryName"
                                value={productForm.categoryName}
                                placeholder="Категория"
                                onChange={(e) => setProductForm({ ...productForm, categoryName: e.target.value })}
                            />
                        </Col>
                    </Row>
                    : null
            }
            <Row>
                <Col md="8">
                    <Input
                        showError
                        type="select"
                        label="Укажите видимость товара:"
                        name="visibleProduct"
                        value={productForm.visibleProduct}
                        placeholder="Ничего"
                        onChange={(e) => setProductForm({ ...productForm, visibleProduct: e.target.value })}
                        options={[
                            { value: true, name: "Публичный" },
                            { value: false, name: "Только админ" },
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Input
                        showError
                        type="select"
                        label="Укажите видимость цены:"
                        name="visibilityPrice"
                        value={productForm.visibilityPrice}
                        placeholder="Ничего"
                        onChange={(e) => setProductForm({ ...productForm, visibilityPrice: e.target.value })}
                        options={[
                            { value: "private", name: "Никому" },
                            { value: "public", name: "Публичный" },
                            { value: "admin", name: "Только админ" },
                        ]}
                    />
                </Col>
                {
                    visibleAllInput
                        ? <Col md="6">
                            <Input
                                label="Цена"
                                name="price"
                                value={productForm.price}
                                placeholder={`Цена`}
                                onChange={(e) => setProductForm({ ...productForm, price: e.target.value })}
                            />
                        </Col>
                        : null
                }
            </Row>
            {
                visibleAllInput
                    ? <Input
                        label="Ссылка для перехода"
                        name="categorySlug"
                        value={productForm.categorySlug}
                        placeholder="Ссылка"
                        onChange={(e) => setProductForm({ ...productForm, categorySlug: e.target.value })}
                    />
                    : null
            }
            <textarea
                style={{
                    width: "100%",
                    minHeight: "100px"
                }}
                label="Подробное описание"
                name="description"
                value={productForm.description}
                placeholder={`Подробное описание`}
                onChange={(e) => setProductForm({ ...productForm, description: e.target.value })}
            />
            {
                visibleAllInput
                    ? <>
                        <textarea
                            style={{
                                width: "100%",
                                minHeight: "200px"
                            }}
                            label="Видимое подробное описание"
                            name="descriptionNew"
                            value={productForm.descriptionNew}
                            placeholder={`Видимое подробное описание`}
                            onChange={(e) => setProductForm({ ...productForm, descriptionNew: e.target.value })}
                        />

                        <Input
                            label="Размеры заготовки"
                            name="size"
                            value={productForm.size}
                            placeholder={`Введите размеры`}
                            onChange={(e) => setProductForm({ ...productForm, size: e.target.value })}
                        />
                        <Input
                            label="Вес готового изделия"
                            name="weight"
                            value={productForm.weight}
                            placeholder={`Вес`}
                            onChange={(e) => setProductForm({ ...productForm, weight: e.target.value })}
                        />

                    </>
                    : null
            }
            {
                !currentId ?
                    <Row>
                        <Col md="8">
                            <Input
                                showError
                                type="select"
                                label="Укажите категорию:"
                                name="categoryId"
                                value={productForm.categoryId}
                                placeholder="Выбрать категорию"
                                onChange={(e) => setProductForm({ ...productForm, categoryId: e.target.value })}
                                options={categoryList}
                            />
                        </Col>
                    </Row>
                    : null
            }

            {!currentId ? <>
                <BRenderAddPictures
                    setPictures={setProductPictures}
                    pictures={productPictures}
                />
                {
                    !currentId ?
                        <Row>
                            <Col md="8">
                                <Input
                                    showError
                                    type="select"
                                    label="Водный знак:"
                                    name="visibleWatermark"
                                    value={productForm.visibleWatermark}
                                    placeholder="Выбрать"
                                    onChange={(e) => setProductForm({ ...productForm, visibleWatermark: e.target.value })}
                                    options={[
                                        { value: "visible", name: "Наложить" },
                                        { value: "hide", name: "Без знака" },
                                    ]}
                                />
                            </Col>
                        </Row>
                        : null
                }
            </> : null
            }
            <Button
                variant="contained"
                onClick={() => setVisibleAllInput(!visibleAllInput)}
            >
                {!visibleAllInput
                    ? "Все параметры"
                    : "Только основные"
                }
            </Button>
        </Modal>
    )
}
