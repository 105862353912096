import { mainActionsConstants, roleChangeRequest } from '../actions/constants';

const initState = {
  roleChangeRequests: [],
  roleChangeRequestObj: {},
  loading: false,
  loadingStatus: false,
  message: "",
  showAlert: false,
  variantAlert: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case roleChangeRequest.UPDATE_STATUS_REQUEST:
      state = {
        ...state,
        loadingStatus: true
      };
      break;
    case roleChangeRequest.UPDATE_STATUS_SUCCESS:
      state = {
        ...state,
        roleChangeRequestObj: action.payload?.item,
        loadingStatus: false,
      };
      break;
    case roleChangeRequest.UPDATE_STATUS_FAILURE:
      state = {
        ...state,
        loadingStatus: false,
      };
      break;

    case mainActionsConstants.GET_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case mainActionsConstants.GET_BY_ID_SUCCESS:
      state = {
        ...state,
        roleChangeRequestObj: action.payload?.item,
        loading: false,
      };
      break;
    case mainActionsConstants.GET_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case roleChangeRequest.GET_ALL_REQUEST:
      state = {
        ...state,
        loading: true
      };
    case roleChangeRequest.GET_ALL_SUCCESS:
      state = {
        ...state,
        roleChangeRequests: action.payload?.items,
        loading: false,
      };
      break;
    case roleChangeRequest.GET_ALL_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      return state
  }
  return state;
};