import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "../../actions";
import { RenderOrders } from './RenderOrders';
import { MainContainer } from './../MainContainer';
import { getCustomerOrders, deleteOrderById, updateOrderTotalAmount, updateOrderVisibleByIdAdmin } from './../../actions/order.action';
import OrderModalCenter from './OrderModalCenter';
import RenderSettingOrderProductModal from './RenderSettingOrderProductModal';
import RenderAddAdditionalFieldModal from './RenderAddAdditionalFieldModal';
import AlertComponent from './../../components/Alert/Alert';
import { setCloseAlertAdditionalField } from './../../reducers/order.reducer';
import AddPaymentAccountModal from './PaymentAccount/AddPaymentAccountModal';
import RenderSettingOrderNumberModal from './RenderSettingOrderNumberModal';


const Orders = () => {
  const order = useSelector((state) => state.order);
  const { orders, showAlert, variantAlert, message, loading } = order
  const dispatch = useDispatch();

  const [type, setType] = useState("");
  const [show, setShow] = useState(false);
  const [clicked, setClicked] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderedProduct, setOrderedProduct] = useState([])
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [productOrderDetails, setProductOrderDetails] = useState(null)
  const [showAddAdditionalField, setShowAddAdditionalField] = useState(false)
  const [showPaymentAccountModal, setShowPaymentAccountModal] = useState(false)
  const [showOrderSettingModal, setShowOrderSettingModal] = useState(false)
  const [showDeletingConfirmation, setShowDeletingConfirmation] = useState(false)
  const [showOrderNumberAdditionalModal, setShowOrderNumberAdditionalModal] = useState(false)
  const [typeOrderModalCenter, setTypeOrderModalCenter] = useState("")
  const [_orderId, setOrderId] = useState("");
  const [orderObj, setOrderObj] = useState(null);
  const [dataAdditional, setDataAdditional] = useState(false)
  const [showSettingEditModal, setShowSettingEditModal] = useState(false)
  const [typeEditOrderModal, setTypeEditOrderModal] = useState("")
  const [valueTabsSort, setValueTabsSort] = useState("")
  const [searchMainName, setSearchMainName] = useState("")

  useEffect(() => {
    if (showSettingModal || showAddAdditionalField) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showSettingModal, showAddAdditionalField]);


  useEffect(() => {
    if (searchMainName !== "") {
      dispatch(getCustomerOrders(searchMainName, valueTabsSort))

    } else {
      dispatch(getCustomerOrders("", valueTabsSort))
    }
  }, [valueTabsSort])


  const orderProduct = (orderItem) => {
    const orderArray = orderItem.items.map((item) => item)
    setOrderedProduct(orderArray)
  }

  const onOrderUpdate = (orderId) => {
    const payload = {
      orderId,
      type,
    };
    dispatch(updateOrder(payload));
    setModalShow(false)
  };

  const handleShow = () => setShow(true)

  const handleShowSettingModal = (item) => {
    setShowSettingModal(true)
    setProductOrderDetails(item)
  }

  const handleShowEditOrderModal = (type) => {
    switch (type) {
      case "deliveryId":
        setTypeEditOrderModal(type);
        break;
      case "address":
        setTypeEditOrderModal(type);
        break;
      case "pickupPointsId":
        setTypeEditOrderModal(type);
        break;
      case "paymentId":
        setTypeEditOrderModal(type);
        break;
      default:
        setTypeEditOrderModal("");
        break;
    }
  }
  const showOrderModal = (orderItem, type) => {
    switch (type) {
      case "deliveryId":
        setTypeOrderModalCenter(type);
        break;
      case "address":
        setTypeOrderModalCenter(type);
        break;
      case "pickupPointsId":
        setTypeOrderModalCenter(type);
        break;
      case "paymentId":
        setTypeOrderModalCenter(type);
        break;
      default:
        setTypeOrderModalCenter("");
        break;
    }
    setOrderDetails(orderItem);
    setModalShow(!modalShow);
  };
  const confirmDelete = (deleteId) => {
    dispatch(deleteOrderById(deleteId))
    setShowDeletingConfirmation(false)
    setClicked(null)
  }
  const confirmVisibleOrderById = () => {
    const payload = {
      orderId: _orderId
    }
    dispatch(updateOrderVisibleByIdAdmin(payload))
  }
  const handleTotalAmount = (totalAmount) => {
    const payload = {
      typeSetMain: "totalAmount",
      totalAmount: totalAmount,
      orderId: _orderId
    }
    dispatch(updateOrderTotalAmount(payload))
  }

  const formatDate = (date) => {
    if (date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    }
    return "";
  };

  return (
    <MainContainer
      setValueTabsSort={setValueTabsSort}
      buttonText="Оформить заказ вручную"
      handleShow={handleShow}
      get={getCustomerOrders}
      sortType={valueTabsSort}
      setSearchMainName={setSearchMainName}
      search={getCustomerOrders}
      title="Все заказы"
      noneHrefButton
      propsType
      fluid
      type="fullFunctional"
      tabs
    >
      <OrderModalCenter
        typeOrderModalCenter={typeOrderModalCenter}
        onHide={() => setModalShow(false)}
        onOrderUpdate={onOrderUpdate}
        formatDate={formatDate}
        details={orderDetails}
        orderObj={orderObj}
        setType={setType}
        loading={loading}
        show={modalShow}
      />
      {searchMainName !== "" && <div style={{
        margin: "20px auto",
        textAlign: "center"
      }}>
        <h2>Результаты поиска "{searchMainName}"</h2>
        <h4>Найдено "{orders.length}" заказов</h4>

      </div>}
      <RenderOrders
        searchMainName={searchMainName}
        typeEditOrderModal={typeEditOrderModal}
        handleShowEditOrderModal={handleShowEditOrderModal}
        showSettingEditModal={showSettingEditModal}
        setShowSettingEditModal={setShowSettingEditModal}
        setShowOrderNumberAdditionalModal={setShowOrderNumberAdditionalModal}
        setShowDeletingConfirmation={setShowDeletingConfirmation}
        setShowPaymentAccountModal={setShowPaymentAccountModal}
        showDeletingConfirmation={showDeletingConfirmation}
        setShowOrderSettingModal={setShowOrderSettingModal}
        confirmVisibleOrderById={confirmVisibleOrderById}
        handleShowSettingModal={handleShowSettingModal}
        handleTotalAmount={handleTotalAmount}
        orderedProduct={orderedProduct}
        showOrderModal={showOrderModal}
        confirmDelete={confirmDelete}
        orderProduct={orderProduct}
        setOrderObj={setOrderObj}
        setOrderId={setOrderId}
        setClicked={setClicked}
        orderObj={orderObj}
        orderId={_orderId}
        loading={loading}
        clicked={clicked}
        order={orders}
      />
      <RenderSettingOrderProductModal
        setShowAddAdditionalField={setShowAddAdditionalField}
        onHide={() => setShowSettingModal(false)}
        productOrderDetails={productOrderDetails}
        dataAdditional={dataAdditional}
        show={showSettingModal}
        orderObj={orderObj}
        loading={loading}
      />
      <RenderAddAdditionalFieldModal
        setShowAddAdditionalField={setShowAddAdditionalField}
        showAddAdditionalField={showAddAdditionalField}
        onHide={() => setShowAddAdditionalField(false)}
        productOrderDetails={productOrderDetails}
        setDataAdditional={setDataAdditional}
        orderId={_orderId}
      />

      <AddPaymentAccountModal
        orderObj={orderObj}
        show={showPaymentAccountModal}
        onHide={() => setShowPaymentAccountModal(false)}
        orderId={_orderId}
      />
      <RenderSettingOrderNumberModal
        orderObj={orderObj}
        show={showOrderNumberAdditionalModal}
        onHide={() => setShowOrderNumberAdditionalModal(false)}
        orderId={_orderId}
      />
      <AlertComponent
        dispatchCloseAlert={setCloseAlertAdditionalField}
        color={variantAlert}
        showObj={showAlert}
        text={message}
      />
    </MainContainer>
  );
};

export default Orders;