import { Button } from '@mui/material'
import React from 'react'

const ButtonContainer = ({
    setTypeModal
}) => {
    const buttonItem = [
        {
            text: "Подписки",
            typeM: "subscribers"
        },
        {
            text: "Корзина",
            typeM: "cart"
        },
        {
            text: "Понравившиеся товары",
            typeM: "desires"
        },
        {
            text: "История товаров",
            typeM: "productHistory"
        },
    ]

    return (
        <div>
            {buttonItem.map((item, index) =>
                <Button
                    style={{
                        margin: "5px"
                    }}
                    variant='contained'
                    key={index}
                    onClick={() => setTypeModal(item.typeM)}
                >
                    {item.text}
                </Button>
            )}
        </div>
    )
}

export default ButtonContainer