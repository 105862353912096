import React, { useEffect } from 'react'
import Modal from "../../components/UI/Modal";
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from '../../actions';
import DetailsContent from './DetailsContent';
import SubscribersContent from './SubscribersContent';
import UserProductContainer from './UserProductContainer';
import UserHistoryItems from './UserHistoryItems';
import { clearUserInfoCart } from '../../reducers/user.reducer';

const RenderModalContent = ({
    handleCloseUserDetailsModal,
    userDetailModal,
    setTypeModal,
    userDetails,
    currentId,
    typeModal,
}) => {
    const {
        cart,
        desires,
        loading,
        message
    } = useSelector((state) => state.user)

    const dispatch = useDispatch();

    useEffect(() => {
        if (currentId && currentId !== userDetails._id) {
            dispatch(getUserById(currentId))
        }
    }, [currentId])

    useEffect(() => {
        if (typeModal === "") {
            dispatch(clearUserInfoCart())
        }
    }, [typeModal])


    const renderContent = () => {
        let content, title;
        switch (typeModal) {
            case "subscribers":
                content = <SubscribersContent
                    setTypeModal={setTypeModal}
                    userDetails={userDetails}
                />
                title = "Подписки пользователя"
                break;
            case "cart":
                content = <UserProductContainer
                    setTypeModal={setTypeModal}
                    userDetails={userDetails}
                    typeModal={typeModal}
                    userId={currentId}
                    loading={loading}
                    message={message}
                    cart={cart}
                />
                title = "Товары в корзине"
                break;
            case "desires":
                content = <UserProductContainer
                    setTypeModal={setTypeModal}
                    userDetails={userDetails}
                    typeModal={typeModal}
                    userId={currentId}
                    desires={desires}
                    loading={loading}
                    message={message}
                />
                title = "Товары в понравившихся"
                break;
            case "productHistory":
                content = <UserHistoryItems
                    setTypeModal={setTypeModal}
                    userDetails={userDetails}
                    typeModal={typeModal}
                    userId={currentId}
                    loading={loading}
                    message={message}
                />
                title = "История просмотров товаров"
                break;
            default:
                content = <DetailsContent
                    setTypeModal={setTypeModal}
                    userDetails={userDetails}
                />
                title = "Подробно про пользователя"
        }
        return { content, title }
    }
    return (
        <Modal
            handleClose={handleCloseUserDetailsModal}
            modalTitle={renderContent().title}
            show={userDetailModal}
            size="lg"
        >
            {renderContent().content}
        </Modal>
    )
}

export default RenderModalContent