import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import StatusOrder from '../Orders/StatusOrder';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

export const RenderItems = ({
    applicationsCallMe,
    setTypeModal,
    showModal,
    typeItems,
    deleteDispatch
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        if (selectAll) {
            const allIds = applicationsCallMe.map(a => a._id);
            setSelectedItems(allIds);
        } else {
            setSelectedItems([]);
        }
    }, [selectAll, applicationsCallMe]);

    const handleCheckboxChange = (e, id) => {
        e.stopPropagation();
        if (e.target.checked) {
            setSelectedItems(prevItems => [...prevItems, id]);
        } else {
            setSelectedItems(prevItems => prevItems.filter(itemId => itemId !== id));
        }
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
    };

    if (applicationsCallMe?.length === 0) {
        return <Loader />;
    }

    const handleShowSettingModal = (e, type, id) => {
        e.stopPropagation();
        setTypeModal(type);
        showModal(id);
    };

    const handleDeleteMany = () => {
        let payload;
        if (selectedItems.length > 0) {
            payload = {
                ids: selectedItems
            }
            dispatch(deleteDispatch(payload))
        }
    }
    const filterOrderStatusColor = (text) => {
        let color;
        switch (text) {
            case "Оформлена":
                color = "gray";
                break;
            case "На рассмотрении":
                color = "orange";
                break;
            case "Подтверждена оператором":
                color = "green";
                break;
            case "Отклонено":
                color = "red";
                break;
            default:
                color = "gray";
        }
        return color;
    };
    //переделать так чтобы работало свойство с разными заявками, переждать диспатч через пропсы 


    return (
        <div>
            <Button
                onClick={handleSelectAll}
            >
                Выделить все
            </Button>
            <Button
                disabled={true}
                onClick={handleDeleteMany}
            >
                Удалить выбранные
            </Button>
            <div className="tableContainer">
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Статус</th>
                            <th scope="col">Мобильный</th>
                            <th scope="col">Имя</th>
                            <th scope="col">Email</th>
                            <th scope="col">Время</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applicationsCallMe?.length > 0
                            ? applicationsCallMe?.map((a) =>
                                <tr key={a._id}
                                    onClick={() => showModal(a._id, a.isViewed)}
                                    style={{
                                        position: "relative",
                                        background: !a.isViewed ? "rgba(19, 98, 202, 0.164)" : ""
                                    }}
                                >

                                    <th scope="row">{a.applicationNumber}</th>
                                    <td>  <StatusOrder
                                        filterOrderStatusColor={filterOrderStatusColor}
                                        status={a.applicationCallMeStatus || a.applicationStatus}
                                        mini
                                    /></td>
                                    <td>{
                                        typeItems === "changeProfile"
                                            ? `${a.userObj.contactNumber}`
                                            : a.contactNumber}</td>
                                    <td>{
                                        typeItems === "changeProfile"
                                            ? `${a.userObj.firstName + " " + a.userObj.lastName}`
                                            : a.fullName}
                                    </td>
                                    <td>{
                                        typeItems === "changeProfile"
                                            ? a.userObj.email
                                            : a.email}
                                    </td>
                                    <td>{a.createdAt.slice(11, 19)}</td>
                                    <SettingIconBlock
                                        tooltip="Настройки"
                                        right="10px"
                                        top="8px"
                                        onClick={(e) => handleShowSettingModal(e, "settingModal", a._id, a.isViewed)}
                                    />
                                    {a.isViewed
                                        ? false
                                        : <SettingIconBlock
                                            tooltip="Новая заявка"
                                            right="-20px"
                                            icon={<NotificationsActiveIcon />}
                                            top="8px"
                                            onClick={() => { }}
                                        />}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "10px",
                                            left: "-20px"
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    >

                                        <input
                                            type="checkbox"
                                            checked={selectedItems.includes(a._id)}
                                            onChange={(e) => handleCheckboxChange(e, a._id)}
                                        />
                                    </div>

                                </tr>
                            )
                            : <div style={{
                                margin: "20px"
                            }}>Пока нет созданных заявок</div>}
                    </tbody>
                </table>
            </div>

        </div>
    )
}
