import React, { useEffect } from 'react';
import Loader from '../../components/Loader';
import MailingRenderProductItems from './MailingRenderProductItems';
import { useDispatch, useSelector } from 'react-redux';
import { getMainImage } from './../../actions/components/mainImage';

const HtmlMailingContent = ({
    products,
    loading,
    contact
}) => {
    const productArray = products
    const { mainImage } = useSelector((state) => state.mainImage)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMainImage())
    }, [])

    return !productArray > 0
        ? <Loader />
        : <MailingRenderProductItems
            contact={contact}
            mainImage={mainImage}
            productArray={productArray}
        />
}

export default HtmlMailingContent