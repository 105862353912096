import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Input from './../../components/UI/Input/index';
import { updateOrderDiscountedTotalAmount, updateOrderVisibleDiscountedTotal } from '../../actions';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const TotalAmountContainer = ({
    totalAmount,
    handleTotalAmount,
    orderItem
}) => {
    const { visibleDiscountedTotal, discountedAmount, discountForBuyers } = orderItem

    const [show, setShow] = useState(false)
    const [discountPercentage, setDiscountPercentage] = useState(discountedAmount > 1 ? discountedAmount : "")
    const [_discountForBuyers, setDiscountForBuyers] = useState(discountForBuyers > 1 ? discountForBuyers : "")

    const dispatch = useDispatch()

    const handleChangeTotalAmountDiscount = () => {
        const discountFraction = parseFloat(discountPercentage) / 100
        const discountedTotalAmount = totalAmount() * (1 - discountFraction)
        setDiscountForBuyers(discountedTotalAmount)
    }

    const handleSubmitOrderDiscount = () => {
        const payload = {
            discountedAmount: discountPercentage,
            discountForBuyers: _discountForBuyers,
            orderId: orderItem._id
        }
        dispatch(updateOrderDiscountedTotalAmount(payload))

    }
    const handleUpdateOrderVisibleDiscountedTotal = () => {
        const payload = {
            orderId: orderItem._id
        }
        dispatch(updateOrderVisibleDiscountedTotal(payload))
    }

    const renderAllPrices = () => {
        return (
            <div className="renderOrderDetails__allPriceContainer">
                <div className="renderOrderDetails__priceBox">
                    <p>
                        Общая сумма:&nbsp;
                    </p>
                    <span>
                        {orderItem.totalAmount} $
                    </span>

                </div>
                <div className="renderOrderDetails__priceDiscountedBox">
                    <p>
                        Установленный процент скидки:&nbsp;
                    </p>
                    <span>
                        {orderItem.discountedAmount} %
                    </span>
                </div>
                <div className="renderOrderDetails__fixedPercentage">
                    <p>
                        Общая сумма со скидкой:&nbsp;
                    </p>
                    <span>
                        {orderItem.discountForBuyers} $
                    </span>
                </div>
            </div>
        )
    }
    return (

        <div className="renderOrderDetails__totalPriceContainer">
            {orderItem.disableFunctionality ? renderAllPrices() : <Button
                style={{ marginTop: "20px" }}
                variant="contained"

                onClick={() => setShow(!show)}
            >
                Настройки Стоимости {show ? "cкрыть" : "показать"}
            </Button>}
            {show && <div className="renderOrderDetails__rowTotalAmount">
                <div className="renderOrderDetails__mainContent">
                    <div className="renderOrderDetails__titleTotalPrice">
                        Общая сумма
                    </div>
                    {totalAmount() > 0
                        ? <div className="renderOrderDetails__totalPrice">
                            {totalAmount()} $
                        </div>
                        : <div className="renderOrderDetails__noTotalPrice">
                            Не везде указанна сумма!
                        </div>
                    }
                    <Button
                        variant="contained"
                        onClick={() => handleTotalAmount(totalAmount())}
                    >
                        Подтвердить сумму
                    </Button>
                    <div>
                        <Button
                            style={{ marginTop: "15px" }}
                            endIcon={visibleDiscountedTotal ? <VisibilityOffIcon /> : <AddIcon />}
                            variant="contained"
                            color={visibleDiscountedTotal ? "success" : "error"}
                            onClick={handleUpdateOrderVisibleDiscountedTotal}
                        >
                            {visibleDiscountedTotal ? "Скрыть" : "Добавить"} скидку
                        </Button>
                    </div>
                </div>

                {visibleDiscountedTotal && <div className="renderOrderDetails__mainContent">
                    <Input
                        label="Процент"
                        name={"discountPercentage"}
                        value={discountPercentage}
                        placeholder={"Введите процент который нужно посчитать"}
                        onChange={(e) => setDiscountPercentage(e.target.value)}

                    />
                    {discountPercentage != "" && <Button
                        variant="outlined"
                        onClick={handleChangeTotalAmountDiscount}
                    >
                        Посчитать
                    </Button>}
                    <div className="renderOrderDetails__titleTotalPrice">
                        Сумма после вычета скидки!
                    </div>
                    {_discountForBuyers > 0
                        ? <div className="renderOrderDetails__totalPrice">
                            {_discountForBuyers} $
                        </div>
                        : <div className="renderOrderDetails__noTotalPrice">
                            Нужно указать число в процентах
                        </div>
                    }
                    <Button
                        variant="contained"
                        onClick={handleSubmitOrderDiscount}
                    >
                        Сохранить сумму со скидкой
                    </Button>
                </div>}
            </div>
            }
        </div>
    )
}

export default TotalAmountContainer