import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useParams } from 'react-router-dom';
import { getProducts } from '../actions';
import { getProductsBySlug } from './../actions/product.action';
import { setPerPage } from '../reducers/product.reducer';
import SelectComponent from './SelectComponent';
import { setPerPageSearchQuery, setSortSearchQuery } from '../reducers/components/searchQuery';


export function Search(props) {
    const dispatch = useDispatch();
    const [searchTimeout, setSearchTimeout] = useState(false);
    const [searchName, setSearchName] = useState("");

    const searchChangeHandler = (e) => {
        setSearchName(e.target.value)
        props.setSearchMainName(e.target.value)
        if (searchTimeout !== false) {
            clearTimeout(searchTimeout)
        }
        if (e.target.value !== "") {
            setSearchTimeout(setTimeout((value) => {
                dispatch(props.search(value, props.sortType, props.sortSearch))
            }, 1000, e.target.value))
        } else {
            if (props.productSort) {
                dispatch(getProducts())
            } else {
                dispatch(props.get("", props.sortType))
            }
        }
    }

    return (
        <>
            <input
                className="disk__search"
                type="text"
                placeholder="Поиск"
                value={searchName}
                onChange={(e) => searchChangeHandler(e)}
            />
        </>
    )
}
export const Sort = ({
    noneSelectUpdatedPictures,
    setUpdatedPictures,
    valueTabsSort,
    dispatchAction,
    updatedPictures,
    setPageNumber,
    setPageSize,
    productSort,
    hideMarked,
    pageNumber,
    sortItems,
    setSortReducer,
    pageSize,
    typeSort,
    get,
}) => {

    const dispatch = useDispatch();
    const [sort, setSort] = useState(sortItems ? sortItems : "createdAtMinus");
    const { slug } = useParams()

    const handleChange = (event) => {
        console.log("event.target.value", event.target.value)
        if (dispatchAction) {
            dispatch(setSortSearchQuery(event.target.value))
        } else {
            setSort(event.target.value)
        }
    };

    const handleChangeUpdatedPictures = (event) => {
        setUpdatedPictures(event.target.value);
    };

    const handlePaginateChange = (event) => {
        if (dispatchAction) {
            dispatch(setPerPageSearchQuery(event.target.value))
            dispatch(setPageNumber(0))
        } else {
            setPageSize(event.target.value)
            setPageNumber(0)
        }
    };

    const selectPageSizeItem = [
        {
            value: 12,
        },
        {
            value: 25,
        },
        {
            value: 50,
        },
        {
            value: 75,
        },
        {
            value: 100,
        }
    ]
    const selectItem = [
        {
            value: "name",
            text: "По названию от А-Я"
        },
        {
            value: "nameMinus",
            text: "По названию от Я-А"
        },
        {
            value: !productSort ? "email" : "price",
            text: !productSort ? "Email A-Z" : "По цене от дорогого к дешевому"
        },
        {
            value: !productSort ? "emailMinus" : "priceMinus",
            text: !productSort ? "Email Z-A" : "По цене от дорогого к дешевому"
        },
        {
            value: "updatedAtMinus",
            text: "Самые последние изменения"
        },
        {
            value: "updatedAt",
            text: "Самые старые изменения"
        },
        {
            value: "createdAtMinus",
            text: "Самые новые"
        },
        {
            value: "createdAt",
            text: "Самые старые"
        },
        {
            value: "byPopularityUp",
            text: "По полулярности вверх"
        },
        {
            value: "byPopularityDown",
            text: "По полулярности вниз"
        },
    ]
    const updatedPicturesArray = [
        {
            text: "Все",
            value: "all"
        },
        {
            text: "Только Готовые",
            value: "updatedPictures"
        },
        {
            text: "Без обновленных",
            value: "picturesNotUpdated"
        },
    ]
    useEffect(() => {
        setPerPage(pageSize)
        if (productSort) {
            if (slug === "all" || slug === "all" && hideMarked) {
                dispatch(getProducts(sort, pageNumber, pageSize, updatedPictures))
            }
            if (slug !== "all" || slug !== "all" && hideMarked) {
                dispatch(getProductsBySlug(slug, sort, pageNumber, pageSize, updatedPictures))
            }
        }

        if (get && sort && pageNumber && pageSize && updatedPictures) {
            dispatch(get(sort, pageNumber, pageSize, updatedPictures))
        }
    }, [sort, pageNumber, pageSize, updatedPictures])//добавил сорт

    useEffect(() => {
        if (sort && typeSort === "user") {
            dispatch(get(sort))
        }
    }, [sort])

    return (
        <div className="sortComponent">
            {noneSelectUpdatedPictures
                ? null
                : <SelectComponent
                    title="Обновленные"
                    selectItem={updatedPicturesArray}
                    value={updatedPictures}
                    handleChange={handleChangeUpdatedPictures}
                    minWidth={200}
                />}
            <SelectComponent
                title="Cортировка"
                selectItem={selectItem}
                value={dispatchAction ? sortItems : sort}
                handleChange={handleChange}
                minWidth={120}
            />
            <SelectComponent
                title="Показывать"
                selectItem={selectPageSizeItem}
                value={pageSize}
                handleChange={handlePaginateChange}
                minWidth={250}
            />
        </div>
    )
}


export const Tabs = ({
    setValueTabsSort,
    itemsType
}) => {
    const [currentIndex, setCurrentIndex] = useState(1);


    const renderTabsItems = () => {
        let content;
        switch (itemsType) {
            case "main": content = [
                { value: "all", text: "Основное" },
                { value: "analytics", text: "Аналитика" },
            ];
                break;
            case "application": content = [
                { value: "all", text: "Все" },
                { value: "ordered", text: "Оформленые" },
                { value: "processing", text: "На рассмотрении" },
                { value: "confirmed", text: "Подтвержденные" },
                { value: "rejected", text: "Отклоненные" },
            ];
                break;
            case "products": content = [
                { value: "all", text: "Все" },
                { value: "nothing", text: "Ничего" },
                { value: "inProgress", text: "В работе" },
                { value: "modelReady", text: "Модель готова" },
                { value: "productInfo", text: "Инфо по товару" },
                { value: "photosUploaded", text: "Фото загружены" },
            ];
                break;
            default: content = [
                { value: "all", text: "Все" },
                { value: "ordered", text: "Новые" },
                { value: "confirmed", text: "Подтвержденные" },
                { value: "work", text: "Производство" },
                { value: "shipped", text: "Передан в службу доставки" },
                { value: "delivered", text: "Доставленные" },
            ];
        }
        return content;
    };
    const handleValueSort = (value) => {
        setValueTabsSort(value);
    };

    return (
        <div className="tabs">
            <div className="tabs__container">
                <div className="tabs__list">
                    {renderTabsItems().map((item, index) => (
                        <div
                            className={
                                currentIndex === index + 1
                                    ? "tabs__item active"
                                    : "tabs__item"
                            }
                            onClick={() => {
                                setCurrentIndex(index + 1);
                                handleValueSort(item.value);
                            }}
                            key={index}
                        >
                            <div className="tabs__link">{item.text}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};