import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getApplication } from '../../actions';
import Loader from '../../components/Loader';
import SettingIconBlock from '../../components/Button/SettingIconBlock';


export const RenderPartnerApplication = ({
    roleChangeRequests,
    setTypeModal,
    showModal,
}) => {
    const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(getApplication())
    // }, [])
    if (!roleChangeRequests) return null

    if (roleChangeRequests?.length === 0) {
        return (
            <Loader />
        )
    }
    const handleShowSettingModal = (e, type, item) => {
        e.stopPropagation()
        setTypeModal(type)
        showModal(item)
    }


    return (
        <div>
            <div className="tableContainer">
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Желаемая роль</th>
                            <th scope="col">Мобильный</th>
                            <th scope="col">Время</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roleChangeRequests.length > 0
                            ? roleChangeRequests.map((item, index) =>
                                <tr key={item._id}
                                    onClick={() => showModal(item)}
                                    style={{
                                        position: "relative"
                                    }}
                                >
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.newRole}</td>
                                    <td>{item.contactNumber}</td>
                                    <td>{item.createdAt.slice(11, 19)}</td>
                                    <SettingIconBlock
                                        tooltip="Настройки"
                                        right="10px"
                                        top="8px"
                                        onClick={(e) => handleShowSettingModal(e, "settingModal", item)}
                                    />
                                </tr>
                            )
                            : null}
                    </tbody>
                </table>
            </div>

        </div>
    )
}
