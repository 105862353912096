import axios from "../helpers/axios";
import { setUser } from "../reducers/user.reducer";
import { userConstants } from './constants';


export const adminToggleSubscriptionCompletion = (subscriptionId, userId) => {
    return async (dispatch) => {
        try {
            const res = await axios.put(`/admin/subscriptions/${subscriptionId}/${userId}`);
            dispatch({ type: userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_REQUEST });
            if (res.status === 200) {
                dispatch({
                    type: userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_FAILURE,
                    payload: res.data,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const addStandardSubscriptions = (userId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.ADD_STANDARD_SUBSCRIPTIONS_REQUEST });
            const res = await axios.post(`/admin/addStandardSubscriptions/${userId}`);
            if (res.status === 201) {
                dispatch({
                    type: userConstants.ADD_STANDARD_SUBSCRIPTIONS_SUCCESS,
                    payload: res.data,
                });
                dispatch(getUsers())
            } else {
                dispatch({
                    type: userConstants.ADD_STANDARD_SUBSCRIPTIONS_FAILURE,
                    payload: res.data,
                });
            }
        } catch (error) {
            const { message } = error.response.data;
            dispatch({
                type: userConstants.ADD_STANDARD_SUBSCRIPTIONS_FAILURE,
                payload: { message },
            });
        }
    };
};

export const updateUserPassword = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.UPDATE_USER_PASSWORD_REQUEST });
            const res = await axios.post("/admin/updateUserPassword", payload);
            if (res.status === 201) {
                const { message, user } = res.data;
                dispatch({
                    type: userConstants.UPDATE_USER_PASSWORD_SUCCESS,
                    payload: { message, user },
                });
                dispatch(getUsers())
            } else {
                const { message } = res.data;
                dispatch({
                    type: userConstants.UPDATE_USER_PASSWORD_FAILURE,
                    payload: { error: message },
                });
            }
        } catch (error) {
            const { message } = error.response.data;
            dispatch({
                type: userConstants.UPDATE_USER_PASSWORD_FAILURE,
                payload: { message },
            });
        }
    };
};
export const updateUser = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.UPDATE_USER_REQUEST });
            const res = await axios.post("/admin/updateUser", payload);
            if (res.status === 201) {
                const { message, user } = res.data;
                dispatch({
                    type: userConstants.UPDATE_USER_SUCCESS,
                    payload: { message, user },
                });
                dispatch(getUsers())
            } else {
                const { message } = res.data;
                dispatch({
                    type: userConstants.UPDATE_USER_FAILURE,
                    payload: { error: message },
                });
            }
        } catch (error) {
            const { message } = error.response.data;
            dispatch({
                type: userConstants.UPDATE_USER_FAILURE,
                payload: { message },
            });
        }
    };
};

export const signup = (user) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.USER_REGISTER_REQUEST });
            const res = await axios.post("/admin/signup", { ...user });
            if (res.status === 201) {
                const { message } = res.data;
                dispatch({
                    type: userConstants.USER_REGISTER_SUCCESS,
                    payload: { message },
                });
            } else {
                const { message } = res.data;
                dispatch({
                    type: userConstants.USER_REGISTER_FAILURE,
                    payload: { error: message },
                });
            }
        } catch (error) {
            const { message } = error.response.data;
            dispatch({
                type: userConstants.USER_REGISTER_FAILURE,
                payload: { message },
            });
        }
    };
};

export const getUserById = (userId) => {
    return async dispatch => {
        dispatch({ type: userConstants.GET_USER_BY_ID_REQUEST });
        let res
        try {
            res = await axios.get(`/admin/getUserById/${userId}`);
            dispatch({
                type: userConstants.GET_USER_BY_ID_SUCCESS,
                payload: { userDetails: res.data.user }
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: userConstants.GET_USER_BY_ID_FAILURE
            });
        }
    }
}

export const getUsers = (sort) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.GET_ALL_USERS_REQUEST })
            let url = '/admin/getUsers'
            if (sort) {
                url = `/admin/getUsers?sort=${sort}`
            }
            const res = await axios.get(url)
            if (res.status === 200) {
                const { users } = res.data;
                dispatch({
                    type: userConstants.GET_ALL_USERS_SUCCESS,
                    payload: { users }
                })
            } else {
                dispatch({ type: userConstants.GET_ALL_USERS_FAILURE })
            }
        } catch (error) {
            console.log(error)
        }

    }
}
export const searchUser = (search) => {
    return async dispatch => {
        try {
            const response = await axios.get(`/admin/getUsers/search?search=${search}`)
            dispatch(setUser(response.data))
        } catch (e) {
            alert(e?.response?.data?.message)
        }
    }
}

export const updateSubscribeEmailAddress = (id) => async (dispatch) => {
    try {
        const data = await axios.get(`/admin/subscribeEmailAddress/${id}`)
        dispatch({
            type: userConstants.UPDATE_SUBSCRIBE_EMAIL_ADDRESS_BY_ID, payload: data
        })
        dispatch(setUser(data))
    } catch (error) {
        console.log(error);
    }
}

export const getUserCart = (userId) => {
    return async (dispatch) => {

        try {
            dispatch({ type: userConstants.GET_USER_CART_REQUEST })
            const res = await axios.get(`/admin/getUserCartProduct/${userId}`)
            const data = res?.data;
            if (res && res.status === 200 && data) {
                dispatch({
                    type: userConstants.GET_USER_CART_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({ type: userConstants.GET_USER_CART_FAILURE })
            }
        } catch (error) {
            console.log(error)
            dispatch({
                type: userConstants.GET_USER_DESIRES_FAILURE,
                payload: error.response?.data
            })
        }

    }
}

export const getUserDesires = (userId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.GET_USER_DESIRES_REQUEST });
            const res = await axios.get(`/admin/getUserDesiresProduct/${userId}`);
            const data = res?.data;
            if (res && res.status === 200 && data) {
                dispatch({
                    type: userConstants.GET_USER_DESIRES_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({ type: userConstants.GET_USER_DESIRES_FAILURE });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: userConstants.GET_USER_DESIRES_FAILURE,
                payload: error.response?.data
            });
        }
    };
};

export const getUserProductViews = (userId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: userConstants.GET_USER_PRODUCT_VIEWS_REQUEST })
            const res = await axios.get(`/admin/getUserProductViews/${userId}`)
            const data = res?.data;
            if (res && res.status === 200 && data) {
                dispatch({
                    type: userConstants.GET_USER_PRODUCT_VIEWS_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: userConstants.GET_USER_PRODUCT_VIEWS_FAILURE })
            }
        } catch (error) {
            console.log(error)
            dispatch({
                type: userConstants.GET_USER_PRODUCT_VIEWS_FAILURE,
                payload: error.response?.data
            })
        }

    }
}