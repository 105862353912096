export const elementPaymentPageConstants = {
  GET_ELEMENT_PAYMENT_PAGE_REQUEST: "GET_ELEMENT_PAYMENT_PAGE_REQUEST",
  GET_ELEMENT_PAYMENT_PAGE_SUCCESS: "GET_ELEMENT_PAYMENT_PAGE_SUCCESS",
  GET_ELEMENT_PAYMENT_PAGE_FAILURE: "GET_ELEMENT_PAYMENT_PAGE_FAILURE",

  ADD_ELEMENT_PAYMENT_PAGE_REQUEST: "ADD_ELEMENT_PAYMENT_PAGE_REQUEST",
  ADD_ELEMENT_PAYMENT_PAGE_SUCCESS: "ADD_ELEMENT_PAYMENT_PAGE_SUCCESS",
  ADD_ELEMENT_PAYMENT_PAGE_FAILURE: "ADD_ELEMENT_PAYMENT_PAGE_FAILURE",

  GET_ELEMENT_PAYMENT_PAGE_BY_ID_REQUEST: "GET_ELEMENT_PAYMENT_PAGE_BY_ID_REQUEST",
  GET_ELEMENT_PAYMENT_PAGE_BY_ID_SUCCESS: "GET_ELEMENT_PAYMENT_PAGE_BY_ID_SUCCESS",
  GET_ELEMENT_PAYMENT_PAGE_BY_ID_FAILURE: "GET_ELEMENT_PAYMENT_PAGE_BY_ID_FAILURE",

  SET_CLOSE_ALERT: "SET_CLOSE_ALERT",
}
export const applicationGalleryConstants = {

  APPLICATION_VIEWED_REQUEST: "APPLICATION_VIEWED_REQUEST",
  APPLICATION_VIEWED_SUCCESS: "APPLICATION_VIEWED_SUCCESS",
  APPLICATION_VIEWED_FAILURE: "APPLICATION_VIEWED_FAILURE",

  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",

  GET_ALL_REQUEST: "GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_FAILURE",

  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

  DELETE_BY_ID_REQUEST: "DELETE_BY_ID_REQUEST",
  DELETE_BY_ID_SUCCESS: "DELETE_BY_ID_SUCCESS",
  DELETE_BY_ID_FAILURE: "DELETE_BY_ID_FAILURE",

  DELETE_MANY_REQUEST: "DELETE_MANY_REQUEST",
  DELETE_MANY_SUCCESS: "DELETE_MANY_SUCCESS",
  DELETE_MANY_FAILURE: "DELETE_MANY_FAILURE",
  CLOSE_ALERT: "CLOSE_ALERT",

}

export const roleChangeRequest = {
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",

  GET_ALL_REQUEST: "GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "GET_ALL_FAILURE",

  DELETE_BY_ID_REQUEST: "DELETE_BY_ID_REQUEST",
  DELETE_BY_ID_SUCCESS: "DELETE_BY_ID_SUCCESS",
  DELETE_BY_ID_FAILURE: "DELETE_BY_ID_FAILURE",
}

export const mainActionsConstants = {
  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

  UPDATE_VIEW_BY_ID_REQUEST: "UPDATE_VIEW_BY_ID_REQUEST",
  UPDATE_VIEW_BY_ID_SUCCESS: "UPDATE_VIEW_BY_ID_SUCCESS",
  UPDATE_VIEW_BY_ID_FAILURE: "UPDATE_VIEW_BY_ID_FAILURE",

  GET_ALL_ITEMS_REQUEST: "GET_ALL_ITEMS_REQUEST",
  GET_ALL_ITEMS_SUCCESS: "GET_ALL_ITEMS_SUCCESS",
  GET_ALL_ITEMS_FAILURE: "GET_ALL_ITEMS_FAILURE",

  CANCELLATION_STATUS_REQUEST: "CANCELLATION_STATUS_REQUEST",
  CANCELLATION_STATUS_SUCCESS: "CANCELLATION_STATUS_SUCCESS",
  CANCELLATION_STATUS_FAILURE: "CANCELLATION_STATUS_FAILURE",

  SET_CLOSE_ALERT: "SET_CLOSE_ALERT",
}

export const paymentAccountConstants = {
  GET_PAYMENT_ACCOUNT_BY_ORDER_ID_REQUEST: "GET_PAYMENT_ACCOUNT_BY_ORDER_ID_REQUEST",
  GET_PAYMENT_ACCOUNT_BY_ORDER_ID_SUCCESS: "GET_PAYMENT_ACCOUNT_BY_ORDER_ID_SUCCESS",
  GET_PAYMENT_ACCOUNT_BY_ORDER_ID_FAILURE: "GET_PAYMENT_ACCOUNT_BY_ORDER_ID_FAILURE",

  GET_PAYMENT_ACCOUNT_REQUEST: "GET_PAYMENT_ACCOUNT_REQUEST",
  GET_PAYMENT_ACCOUNT_SUCCESS: "GET_PAYMENT_ACCOUNT_SUCCESS",
  GET_PAYMENT_ACCOUNT_FAILURE: "GET_PAYMENT_ACCOUNT_FAILURE",

  ADD_PAYMENT_ACCOUNT_REQUEST: "ADD_PAYMENT_ACCOUNT_REQUEST",
  ADD_PAYMENT_ACCOUNT_SUCCESS: "ADD_PAYMENT_ACCOUNT_SUCCESS",
  ADD_PAYMENT_ACCOUNT_FAILURE: "ADD_PAYMENT_ACCOUNT_FAILURE",

  UPDATE_PAYMENT_ACCOUNT_REQUEST: "UPDATE_PAYMENT_ACCOUNT_REQUEST",
  UPDATE_PAYMENT_ACCOUNT_SUCCESS: "UPDATE_PAYMENT_ACCOUNT_SUCCESS",
  UPDATE_PAYMENT_ACCOUNT_FAILURE: "UPDATE_PAYMENT_ACCOUNT_FAILURE",

  DELETE_PAYMENT_ACCOUNT_REQUEST: "DELETE_PAYMENT_ACCOUNT_REQUEST",
  DELETE_PAYMENT_ACCOUNT_SUCCESS: "DELETE_PAYMENT_ACCOUNT_SUCCESS",
  DELETE_PAYMENT_ACCOUNT_FAILURE: "DELETE_PAYMENT_ACCOUNT_FAILURE",

  GET_PAYMENT_ACCOUNT_BY_ID_REQUEST: "GET_PAYMENT_ACCOUNT_BY_ID_REQUEST",
  GET_PAYMENT_ACCOUNT_BY_ID_SUCCESS: "GET_PAYMENT_ACCOUNT_BY_ID_SUCCESS",
  GET_PAYMENT_ACCOUNT_BY_ID_FAILURE: "GET_PAYMENT_ACCOUNT_BY_ID_FAILURE",

  SET_CLOSE_ALERT_PAYMENT_ACCOUNT: "SET_CLOSE_ALERT_PAYMENT_ACCOUNT",


}
export const colorProductConstants = {
  GET_COLOR_PRODUCT_REQUEST: "GET_COLOR_PRODUCT_REQUEST",
  GET_COLOR_PRODUCT_SUCCESS: "GET_COLOR_PRODUCT_SUCCESS",
  GET_COLOR_PRODUCT_FAILURE: "GET_COLOR_PRODUCT_FAILURE",

  ADD_COLOR_PRODUCT_REQUEST: "ADD_COLOR_PRODUCT_REQUEST",
  ADD_COLOR_PRODUCT_SUCCESS: "ADD_COLOR_PRODUCT_SUCCESS",
  ADD_COLOR_PRODUCT_FAILURE: "ADD_COLOR_PRODUCT_FAILURE",

  UPDATE_COLOR_PRODUCT_REQUEST: "UPDATE_COLOR_PRODUCT_REQUEST",
  UPDATE_COLOR_PRODUCT_SUCCESS: "UPDATE_COLOR_PRODUCT_SUCCESS",
  UPDATE_COLOR_PRODUCT_FAILURE: "UPDATE_COLOR_PRODUCT_FAILURE",

  DELETE_COLOR_PRODUCT_REQUEST: "DELETE_COLOR_PRODUCT_REQUEST",
  DELETE_COLOR_PRODUCT_SUCCESS: "DELETE_COLOR_PRODUCT_SUCCESS",
  DELETE_COLOR_PRODUCT_FAILURE: "DELETE_COLOR_PRODUCT_FAILURE",

  GET_COLOR_PRODUCT_BY_ID_REQUEST: "GET_COLOR_PRODUCT_BY_ID_REQUEST",
  GET_COLOR_PRODUCT_BY_ID_SUCCESS: "GET_COLOR_PRODUCT_BY_ID_SUCCESS",
  GET_COLOR_PRODUCT_BY_ID_FAILURE: "GET_COLOR_PRODUCT_BY_ID_FAILURE",

  SET_CLOSE_ALERT_COLOR_PRODUCT: "SET_CLOSE_ALERT_COLOR_PRODUCT",


}
export const contactMConstants = {
  GET_CONTACT_M_REQUEST: "GET_CONTACT_M_REQUEST",
  GET_CONTACT_M_SUCCESS: "GET_CONTACT_M_SUCCESS",
  GET_CONTACT_M_FAILURE: "GET_CONTACT_M_FAILURE",

  ADD_CONTACT_M_REQUEST: "ADD_CONTACT_M_REQUEST",
  ADD_CONTACT_M_SUCCESS: "ADD_CONTACT_M_SUCCESS",
  ADD_CONTACT_M_FAILURE: "ADD_CONTACT_M_FAILURE",

  UPDATE_CONTACT_M_REQUEST: "UPDATE_CONTACT_M_REQUEST",
  UPDATE_CONTACT_M_SUCCESS: "UPDATE_CONTACT_M_SUCCESS",
  UPDATE_CONTACT_M_FAILURE: "UPDATE_CONTACT_M_FAILURE",

  DELETE_CONTACT_M_REQUEST: "DELETE_CONTACT_M_REQUEST",
  DELETE_CONTACT_M_SUCCESS: "DELETE_CONTACT_M_SUCCESS",
  DELETE_CONTACT_M_FAILURE: "DELETE_CONTACT_M_FAILURE",
}

export const searchQueryConstants = {
  SET_PAGE_SIZE: "SET_PAGE_SIZE",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_SORT: "SET_SORT",

  GET_SEARCH_QUERY_REQUEST: "GET_SEARCH_QUERY_REQUEST",
  GET_SEARCH_QUERY_SUCCESS: "GET_SEARCH_QUERY_SUCCESS",
  GET_SEARCH_QUERY_FAILURE: "GET_SEARCH_QUERY_FAILURE",

  GET_SEARCH_QUERY_BY_ID_REQUEST: "GET_SEARCH_QUERY_BY_ID_REQUEST",
  GET_SEARCH_QUERY_BY_ID_SUCCESS: "GET_SEARCH_QUERY_BY_ID_SUCCESS",
  GET_SEARCH_QUERY_BY_ID_FAILURE: "GET_SEARCH_QUERY_BY_ID_FAILURE",

  ADD_SEARCH_QUERY_REQUEST: "ADD_SEARCH_QUERY_REQUEST",
  ADD_SEARCH_QUERY_SUCCESS: "ADD_SEARCH_QUERY_SUCCESS",
  ADD_SEARCH_QUERY_FAILURE: "ADD_SEARCH_QUERY_FAILURE",

  UPDATE_SEARCH_QUERY_REQUEST: "UPDATE_SEARCH_QUERY_REQUEST",
  UPDATE_SEARCH_QUERY_SUCCESS: "UPDATE_SEARCH_QUERY_SUCCESS",
  UPDATE_SEARCH_QUERY_FAILURE: "UPDATE_SEARCH_QUERY_FAILURE",

  DELETE_SEARCH_QUERY_REQUEST: "DELETE_SEARCH_QUERY_REQUEST",
  DELETE_SEARCH_QUERY_SUCCESS: "DELETE_SEARCH_QUERY_SUCCESS",
  DELETE_SEARCH_QUERY_FAILURE: "DELETE_SEARCH_QUERY_FAILURE",
}

export const pickupPointsConstants = {
  GET_PICKUP_POINTS_REQUEST: "GET_PICKUP_POINTS_REQUEST",
  GET_PICKUP_POINTS_SUCCESS: "GET_PICKUP_POINTS_SUCCESS",
  GET_PICKUP_POINTS_FAILURE: "GET_PICKUP_POINTS_FAILURE",

  GET_PICKUP_POINTS_BY_ID_REQUEST: "GET_PICKUP_POINTS_BY_ID_REQUEST",
  GET_PICKUP_POINTS_BY_ID_SUCCESS: "GET_PICKUP_POINTS_BY_ID_SUCCESS",
  GET_PICKUP_POINTS_BY_ID_FAILURE: "GET_PICKUP_POINTS_BY_ID_FAILURE",

  ADD_PICKUP_POINTS_REQUEST: "ADD_PICKUP_POINTS_REQUEST",
  ADD_PICKUP_POINTS_SUCCESS: "ADD_PICKUP_POINTS_SUCCESS",
  ADD_PICKUP_POINTS_FAILURE: "ADD_PICKUP_POINTS_FAILURE",

  UPDATE_PICKUP_POINTS_REQUEST: "UPDATE_PICKUP_POINTS_REQUEST",
  UPDATE_PICKUP_POINTS_SUCCESS: "UPDATE_PICKUP_POINTS_SUCCESS",
  UPDATE_PICKUP_POINTS_FAILURE: "UPDATE_PICKUP_POINTS_FAILURE",

  DELETE_PICKUP_POINTS_REQUEST: "DELETE_PICKUP_POINTS_REQUEST",
  DELETE_PICKUP_POINTS_SUCCESS: "DELETE_PICKUP_POINTS_SUCCESS",
  DELETE_PICKUP_POINTS_FAILURE: "DELETE_PICKUP_POINTS_FAILURE",
}

export const allQuestionConstants = {
  GET_ALL_QUESTION_REQUEST: "GET_ALL_QUESTION_REQUEST",
  GET_ALL_QUESTION_SUCCESS: "GET_ALL_QUESTION_SUCCESS",
  GET_ALL_QUESTION_FAILURE: "GET_ALL_QUESTION_FAILURE",

  ADD_ALL_QUESTION_REQUEST: "ADD_ALL_QUESTION_REQUEST",
  ADD_ALL_QUESTION_SUCCESS: "ADD_ALL_QUESTION_SUCCESS",
  ADD_ALL_QUESTION_FAILURE: "ADD_ALL_QUESTION_FAILURE",

  UPDATE_ALL_QUESTION_REQUEST: "UPDATE_ALL_QUESTION_REQUEST",
  UPDATE_ALL_QUESTION_SUCCESS: "UPDATE_ALL_QUESTION_SUCCESS",
  UPDATE_ALL_QUESTION_FAILURE: "UPDATE_ALL_QUESTION_FAILURE",

  DELETE_ALL_QUESTION_REQUEST: "DELETE_ALL_QUESTION_REQUEST",
  DELETE_ALL_QUESTION_SUCCESS: "DELETE_ALL_QUESTION_SUCCESS",
  DELETE_ALL_QUESTION_FAILURE: "DELETE_ALL_QUESTION_FAILURE",
}

export const materialConstants = {
  GET_MATERIAL_REQUEST: "GET_MATERIAL_REQUEST",
  GET_MATERIAL_SUCCESS: "GET_MATERIAL_SUCCESS",
  GET_MATERIAL_FAILURE: "GET_MATERIAL_FAILURE",

  ADD_MATERIAL_REQUEST: "ADD_MATERIAL_REQUEST",
  ADD_MATERIAL_SUCCESS: "ADD_MATERIAL_SUCCESS",
  ADD_MATERIAL_FAILURE: "ADD_MATERIAL_FAILURE",

  UPDATE_MATERIAL_REQUEST: "UPDATE_MATERIAL_REQUEST",
  UPDATE_MATERIAL_SUCCESS: "UPDATE_MATERIAL_SUCCESS",
  UPDATE_MATERIAL_FAILURE: "UPDATE_MATERIAL_FAILURE",

  DELETE_MATERIAL_REQUEST: "DELETE_MATERIAL_REQUEST",
  DELETE_MATERIAL_SUCCESS: "DELETE_MATERIAL_SUCCESS",
  DELETE_MATERIAL_FAILURE: "DELETE_MATERIAL_FAILURE",
}

export const modalContentConstants = {
  GET_MODAL_CONTENT_REQUEST: "GET_MODAL_CONTENT_REQUEST",
  GET_MODAL_CONTENT_SUCCESS: "GET_MODAL_CONTENT_SUCCESS",
  GET_MODAL_CONTENT_FAILURE: "GET_MODAL_CONTENT_FAILURE",

  ADD_MODAL_CONTENT_REQUEST: "ADD_MODAL_CONTENT_REQUEST",
  ADD_MODAL_CONTENT_SUCCESS: "ADD_MODAL_CONTENT_SUCCESS",
  ADD_MODAL_CONTENT_FAILURE: "ADD_MODAL_CONTENT_FAILURE",

  UPDATE_MODAL_CONTENT_REQUEST: "UPDATE_MODAL_CONTENT_REQUEST",
  UPDATE_MODAL_CONTENT_SUCCESS: "UPDATE_MODAL_CONTENT_SUCCESS",
  UPDATE_MODAL_CONTENT_FAILURE: "UPDATE_MODAL_CONTENT_FAILURE",

  DELETE_MODAL_CONTENT_REQUEST: "DELETE_MODAL_CONTENT_REQUEST",
  DELETE_MODAL_CONTENT_SUCCESS: "DELETE_MODAL_CONTENT_SUCCESS",
  DELETE_MODAL_CONTENT_FAILURE: "DELETE_MODAL_CONTENT_FAILURE",
}
export const architectLiConstants = {
  GET_ARCHITECT_LI_REQUEST: "GET_ARCHITECT_LI_REQUEST",
  GET_ARCHITECT_LI_SUCCESS: "GET_ARCHITECT_LI_SUCCESS",
  GET_ARCHITECT_LI_FAILURE: "GET_ARCHITECT_LI_FAILURE",

  ADD_ARCHITECT_LI_REQUEST: "ADD_ARCHITECT_LI_REQUEST",
  ADD_ARCHITECT_LI_SUCCESS: "ADD_ARCHITECT_LI_SUCCESS",
  ADD_ARCHITECT_LI_FAILURE: "ADD_ARCHITECT_LI_FAILURE",

  UPDATE_ARCHITECT_LI_REQUEST: "UPDATE_ARCHITECT_LI_REQUEST",
  UPDATE_ARCHITECT_LI_SUCCESS: "UPDATE_ARCHITECT_LI_SUCCESS",
  UPDATE_ARCHITECT_LI_FAILURE: "UPDATE_ARCHITECT_LI_FAILURE",

  DELETE_ARCHITECT_LI_REQUEST: "DELETE_ARCHITECT_LI_REQUEST",
  DELETE_ARCHITECT_LI_SUCCESS: "DELETE_ARCHITECT_LI_SUCCESS",
  DELETE_ARCHITECT_LI_FAILURE: "DELETE_ARCHITECT_LI_FAILURE",
}


export const architectSkillsConstants = {
  GET_ARCHITECT_SKILLS_REQUEST: "GET_ARCHITECT_SKILLS_REQUEST",
  GET_ARCHITECT_SKILLS_SUCCESS: "GET_ARCHITECT_SKILLS_SUCCESS",
  GET_ARCHITECT_SKILLS_FAILURE: "GET_ARCHITECT_SKILLS_FAILURE",

  ADD_ARCHITECT_SKILLS_REQUEST: "ADD_ARCHITECT_SKILLS_REQUEST",
  ADD_ARCHITECT_SKILLS_SUCCESS: "ADD_ARCHITECT_SKILLS_SUCCESS",
  ADD_ARCHITECT_SKILLS_FAILURE: "ADD_ARCHITECT_SKILLS_FAILURE",

  UPDATE_ARCHITECT_SKILLS_REQUEST: "UPDATE_ARCHITECT_SKILLS_REQUEST",
  UPDATE_ARCHITECT_SKILLS_SUCCESS: "UPDATE_ARCHITECT_SKILLS_SUCCESS",
  UPDATE_ARCHITECT_SKILLS_FAILURE: "UPDATE_ARCHITECT_SKILLS_FAILURE",

  DELETE_ARCHITECT_SKILLS_REQUEST: "DELETE_ARCHITECT_SKILLS_REQUEST",
  DELETE_ARCHITECT_SKILLS_SUCCESS: "DELETE_ARCHITECT_SKILLS_SUCCESS",
  DELETE_ARCHITECT_SKILLS_FAILURE: "DELETE_ARCHITECT_SKILLS_FAILURE",
}

export const architectConstants = {
  GET_ARCHITECT_REQUEST: "GET_ARCHITECT_REQUEST",
  GET_ARCHITECT_SUCCESS: "GET_ARCHITECT_SUCCESS",
  GET_ARCHITECT_FAILURE: "GET_ARCHITECT_FAILURE",

  ADD_ARCHITECT_REQUEST: "ADD_ARCHITECT_REQUEST",
  ADD_ARCHITECT_SUCCESS: "ADD_ARCHITECT_SUCCESS",
  ADD_ARCHITECT_FAILURE: "ADD_ARCHITECT_FAILURE",

  UPDATE_ARCHITECT_REQUEST: "UPDATE_ARCHITECT_REQUEST",
  UPDATE_ARCHITECT_SUCCESS: "UPDATE_ARCHITECT_SUCCESS",
  UPDATE_ARCHITECT_FAILURE: "UPDATE_ARCHITECT_FAILURE",

  DELETE_ARCHITECT_REQUEST: "DELETE_ARCHITECT_REQUEST",
  DELETE_ARCHITECT_SUCCESS: "DELETE_ARCHITECT_SUCCESS",
  DELETE_ARCHITECT_FAILURE: "DELETE_ARCHITECT_FAILURE",
}

export const graniteTilesConstants = {
  GET_GRANITE_TILES_REQUEST: "GET_GRANITE_TILES_REQUEST",
  GET_GRANITE_TILES_SUCCESS: "GET_GRANITE_TILES_SUCCESS",
  GET_GRANITE_TILES_FAILURE: "GET_GRANITE_TILES_FAILURE",

  ADD_GRANITE_TILES_REQUEST: "ADD_GRANITE_TILES_REQUEST",
  ADD_GRANITE_TILES_SUCCESS: "ADD_GRANITE_TILES_SUCCESS",
  ADD_GRANITE_TILES_FAILURE: "ADD_GRANITE_TILES_FAILURE",

  UPDATE_GRANITE_TILES_REQUEST: "UPDATE_GRANITE_TILES_REQUEST",
  UPDATE_GRANITE_TILES_SUCCESS: "UPDATE_GRANITE_TILES_SUCCESS",
  UPDATE_GRANITE_TILES_FAILURE: "UPDATE_GRANITE_TILES_FAILURE",

  DELETE_GRANITE_TILES_REQUEST: "DELETE_GRANITE_TILES_REQUEST",
  DELETE_GRANITE_TILES_SUCCESS: "DELETE_GRANITE_TILES_SUCCESS",
  DELETE_GRANITE_TILES_FAILURE: "DELETE_GRANITE_TILES_FAILURE",
  SET_VIEW: "SET_VIEW"
}
export const benefitsConstants = {
  GET_BENEFITS_REQUEST: "GET_BENEFITS_REQUEST",
  GET_BENEFITS_SUCCESS: "GET_BENEFITS_SUCCESS",
  GET_BENEFITS_FAILURE: "GET_BENEFITS_FAILURE",

  ADD_BENEFITS_REQUEST: "ADD_BENEFITS_REQUEST",
  ADD_BENEFITS_SUCCESS: "ADD_BENEFITS_SUCCESS",
  ADD_BENEFITS_FAILURE: "ADD_BENEFITS_FAILURE",

  UPDATE_BENEFITS_REQUEST: "UPDATE_BENEFITS_REQUEST",
  UPDATE_BENEFITS_SUCCESS: "UPDATE_BENEFITS_SUCCESS",
  UPDATE_BENEFITS_FAILURE: "UPDATE_BENEFITS_FAILURE",

  DELETE_BENEFITS_REQUEST: "DELETE_BENEFITS_REQUEST",
  DELETE_BENEFITS_SUCCESS: "DELETE_BENEFITS_SUCCESS",
  DELETE_BENEFITS_FAILURE: "DELETE_BENEFITS_FAILURE",
  SET_VIEW: "SET_VIEW"
}

export const regionConstants = {
  GET_REGION_REQUEST: "GET_REGION_REQUEST",
  GET_REGION_SUCCESS: "GET_REGION_SUCCESS",
  GET_REGION_FAILURE: "GET_REGION_FAILURE",

  ADD_REGION_REQUEST: "ADD_REGION_REQUEST",
  ADD_REGION_SUCCESS: "ADD_REGION_SUCCESS",
  ADD_REGION_FAILURE: "ADD_REGION_FAILURE",

  UPDATE_REGION_REQUEST: "UPDATE_REGION_REQUEST",
  UPDATE_REGION_SUCCESS: "UPDATE_REGION_SUCCESS",
  UPDATE_REGION_FAILURE: "UPDATE_REGION_FAILURE",

  DELETE_REGION_REQUEST: "DELETE_REGION_REQUEST",
  DELETE_REGION_SUCCESS: "DELETE_REGION_SUCCESS",
  DELETE_REGION_FAILURE: "DELETE_REGION_FAILURE",
  SET_VIEW: "SET_VIEW",
  CLOSE_ALERT_TO_REGION: "CLOSE_ALERT_TO_REGION",
}

export const aboutUsConstants = {
  GET_ABOUT_US_REQUEST: "GET_ABOUT_US_REQUEST",
  GET_ABOUT_US_SUCCESS: "GET_ABOUT_US_SUCCESS",
  GET_ABOUT_US_FAILURE: "GET_ABOUT_US_FAILURE",

  ADD_ABOUT_US_REQUEST: "ADD_ABOUT_US_REQUEST",
  ADD_ABOUT_US_SUCCESS: "ADD_ABOUT_US_SUCCESS",
  ADD_ABOUT_US_FAILURE: "ADD_ABOUT_US_FAILURE",

  UPDATE_ABOUT_US_REQUEST: "UPDATE_ABOUT_US_REQUEST",
  UPDATE_ABOUT_US_SUCCESS: "UPDATE_ABOUT_US_SUCCESS",
  UPDATE_ABOUT_US_FAILURE: "UPDATE_ABOUT_US_FAILURE",

  DELETE_ABOUT_US_REQUEST: "DELETE_ABOUT_US_REQUEST",
  DELETE_ABOUT_US_SUCCESS: "DELETE_ABOUT_US_SUCCESS",
  DELETE_ABOUT_US_FAILURE: "DELETE_ABOUT_US_FAILURE",
}
export const paymentListConstants = {
  GET_PAYMENT_LIST_REQUEST: "GET_PAYMENT_LIST_REQUEST",
  GET_PAYMENT_LIST_SUCCESS: "GET_PAYMENT_LIST_SUCCESS",
  GET_PAYMENT_LIST_FAILURE: "GET_PAYMENT_LIST_FAILURE",

  GET_PAYMENT_LIST_BY_ID_REQUEST: "GET_PAYMENT_LIST_BY_ID_REQUEST",
  GET_PAYMENT_LIST_BY_ID_SUCCESS: "GET_PAYMENT_LIST_BY_ID_SUCCESS",
  GET_PAYMENT_LIST_BY_ID_FAILURE: "GET_PAYMENT_LIST_BY_ID_FAILURE",

  ADD_PAYMENT_LIST_REQUEST: "ADD_PAYMENT_LIST_REQUEST",
  ADD_PAYMENT_LIST_SUCCESS: "ADD_PAYMENT_LIST_SUCCESS",
  ADD_PAYMENT_LIST_FAILURE: "ADD_PAYMENT_LIST_FAILURE",

  UPDATE_PAYMENT_LIST_REQUEST: "UPDATE_PAYMENT_LIST_REQUEST",
  UPDATE_PAYMENT_LIST_SUCCESS: "UPDATE_PAYMENT_LIST_SUCCESS",
  UPDATE_PAYMENT_LIST_FAILURE: "UPDATE_PAYMENT_LIST_FAILURE",

  DELETE_PAYMENT_LIST_REQUEST: "DELETE_PAYMENT_LIST_REQUEST",
  DELETE_PAYMENT_LIST_SUCCESS: "DELETE_PAYMENT_LIST_SUCCESS",
  DELETE_PAYMENT_LIST_FAILURE: "DELETE_PAYMENT_LIST_FAILURE",
  SET_VIEW: "SET_VIEW"
}
export const deliveryInfoConstants = {
  GET_DELIVERY_INFO_REQUEST: "GET_DELIVERY_INFO_REQUEST",
  GET_DELIVERY_INFO_SUCCESS: "GET_DELIVERY_INFO_SUCCESS",
  GET_DELIVERY_INFO_FAILURE: "GET_DELIVERY_INFO_FAILURE",

  GET_DELIVERY_INFO_BY_ID_REQUEST: "GET_DELIVERY_INFO_BY_ID_REQUEST",
  GET_DELIVERY_INFO_BY_ID_SUCCESS: "GET_DELIVERY_INFO_BY_ID_SUCCESS",
  GET_DELIVERY_INFO_BY_ID_FAILURE: "GET_DELIVERY_INFO_BY_ID_FAILURE",

  ADD_DELIVERY_INFO_REQUEST: "ADD_DELIVERY_INFO_REQUEST",
  ADD_DELIVERY_INFO_SUCCESS: "ADD_DELIVERY_INFO_SUCCESS",
  ADD_DELIVERY_INFO_FAILURE: "ADD_DELIVERY_INFO_FAILURE",

  UPDATE_DELIVERY_INFO_REQUEST: "UPDATE_DELIVERY_INFO_REQUEST",
  UPDATE_DELIVERY_INFO_SUCCESS: "UPDATE_DELIVERY_INFO_SUCCESS",
  UPDATE_DELIVERY_INFO_FAILURE: "UPDATE_DELIVERY_INFO_FAILURE",

  DELETE_DELIVERY_INFO_REQUEST: "DELETE_DELIVERY_INFO_REQUEST",
  DELETE_DELIVERY_INFO_SUCCESS: "DELETE_DELIVERY_INFO_SUCCESS",
  DELETE_DELIVERY_INFO_FAILURE: "DELETE_DELIVERY_INFO_FAILURE",

}

export const engravingLayoutConstants = {
  GET_ENGRAVING_LAYOUT_REQUEST: "GET_ENGRAVING_LAYOUT_REQUEST",
  GET_ENGRAVING_LAYOUT_SUCCESS: "GET_ENGRAVING_LAYOUT_SUCCESS",
  GET_ENGRAVING_LAYOUT_FAILURE: "GET_ENGRAVING_LAYOUT_FAILURE",

  GET_ENGRAVING_LAYOUT_BY_SLUG_REQUEST: "GET_ENGRAVING_LAYOUT_BY_SLUG_REQUEST",
  GET_ENGRAVING_LAYOUT_BY_SLUG_SUCCESS: "GET_ENGRAVING_LAYOUT_BY_SLUG_SUCCESS",
  GET_ENGRAVING_LAYOUT_BY_SLUG_FAILURE: "GET_ENGRAVING_LAYOUT_BY_SLUG_FAILURE",

  ADD_ENGRAVING_LAYOUT_REQUEST: "ADD_ENGRAVING_LAYOUT_REQUEST",
  ADD_ENGRAVING_LAYOUT_SUCCESS: "ADD_ENGRAVING_LAYOUT_SUCCESS",
  ADD_ENGRAVING_LAYOUT_FAILURE: "ADD_ENGRAVING_LAYOUT_FAILURE",

  UPDATE_ENGRAVING_LAYOUT_REQUEST: "UPDATE_ENGRAVING_LAYOUT_REQUEST",
  UPDATE_ENGRAVING_LAYOUT_SUCCESS: "UPDATE_ENGRAVING_LAYOUT_SUCCESS",
  UPDATE_ENGRAVING_LAYOUT_FAILURE: "UPDATE_ENGRAVING_LAYOUT_FAILURE",

  DELETE_ENGRAVING_LAYOUT_REQUEST: "DELETE_ENGRAVING_LAYOUT_REQUEST",
  DELETE_ENGRAVING_LAYOUT_SUCCESS: "DELETE_ENGRAVING_LAYOUT_SUCCESS",
  DELETE_ENGRAVING_LAYOUT_FAILURE: "DELETE_ENGRAVING_LAYOUT_FAILURE",
}
export const engravingCategoryConstants = {
  GET_ENGRAVING_CATEGORY_REQUEST: "GET_ENGRAVING_CATEGORY_REQUEST",
  GET_ENGRAVING_CATEGORY_SUCCESS: "GET_ENGRAVING_CATEGORY_SUCCESS",
  GET_ENGRAVING_CATEGORY_FAILURE: "GET_ENGRAVING_CATEGORY_FAILURE",

  ADD_ENGRAVING_CATEGORY_REQUEST: "ADD_ENGRAVING_CATEGORY_REQUEST",
  ADD_ENGRAVING_CATEGORY_SUCCESS: "ADD_ENGRAVING_CATEGORY_SUCCESS",
  ADD_ENGRAVING_CATEGORY_FAILURE: "ADD_ENGRAVING_CATEGORY_FAILURE",

  UPDATE_ENGRAVING_CATEGORY_REQUEST: "UPDATE_ENGRAVING_CATEGORY_REQUEST",
  UPDATE_ENGRAVING_CATEGORY_SUCCESS: "UPDATE_ENGRAVING_CATEGORY_SUCCESS",
  UPDATE_ENGRAVING_CATEGORY_FAILURE: "UPDATE_ENGRAVING_CATEGORY_FAILURE",

  DELETE_ENGRAVING_CATEGORY_REQUEST: "DELETE_ENGRAVING_CATEGORY_REQUEST",
  DELETE_ENGRAVING_CATEGORY_SUCCESS: "DELETE_ENGRAVING_CATEGORY_SUCCESS",
  DELETE_ENGRAVING_CATEGORY_FAILURE: "DELETE_ENGRAVING_CATEGORY_FAILURE",
}






export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  NEED_TO_LOGIN: "NEED_TO_LOGIN",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  CLOSE_ALERT_TO_AUTH: "CLOSE_ALERT_TO_AUTH"
};

export const userConstants = {
  EDIT_TYPE_MODAL_USER: "EDIT_TYPE_MODAL_USER",
  SHOW_MODAL_CART_FALSE: "SHOW_MODAL_CART_FALSE",
  SHOW_MODAL_CART: "SHOW_MODAL_CART",
  CLEAR_USER_INFO_CART: "CLEAR_USER_INFO_CART",
  CLEAR_USER_INFO_DESIRES: "CLEAR_USER_INFO_DESIRES",

  UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_REQUEST: "UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_REQUEST",
  UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_SUCCESS: "UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_SUCCESS",
  UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_FAILURE: "UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_FAILURE",

  ADD_STANDARD_SUBSCRIPTIONS_REQUEST: "ADD_STANDARD_SUBSCRIPTIONS_REQUEST",
  ADD_STANDARD_SUBSCRIPTIONS_SUCCESS: "ADD_STANDARD_SUBSCRIPTIONS_SUCCESS",
  ADD_STANDARD_SUBSCRIPTIONS_FAILURE: "ADD_STANDARD_SUBSCRIPTIONS_FAILURE",

  GET_USER_PRODUCT_VIEWS_REQUEST: "GET_USER_PRODUCT_VIEWS_REQUEST",
  GET_USER_PRODUCT_VIEWS_SUCCESS: "GET_USER_PRODUCT_VIEWS_SUCCESS",
  GET_USER_PRODUCT_VIEWS_FAILURE: "GET_USER_PRODUCT_VIEWS_FAILURE",

  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILURE: "USER_REGISTER_FAILURE",

  GET_USER_CART_REQUEST: "GET_USER_CART_REQUEST",
  GET_USER_CART_SUCCESS: "GET_USER_CART_SUCCESS",
  GET_USER_CART_FAILURE: "GET_USER_CART_FAILURE",

  GET_USER_DESIRES_REQUEST: "GET_USER_DESIRES_REQUEST",
  GET_USER_DESIRES_SUCCESS: "GET_USER_DESIRES_SUCCESS",
  GET_USER_DESIRES_FAILURE: "GET_USER_DESIRES_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  UPDATE_USER_PASSWORD_REQUEST: "UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_FAILURE: "UPDATE_USER_PASSWORD_FAILURE",

  GET_ALL_USERS_REQUEST: "GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",

  CLOSE_ALERT_TO_REGISTER_ADMIN: "CLOSE_ALERT_TO_REGISTER_ADMIN",
  UPDATE_SUBSCRIBE_EMAIL_ADDRESS_BY_ID: "UPDATE_SUBSCRIBE_EMAIL_ADDRESS_BY_ID"
};

export const mailingConstants = {
  SEND_MAILING_REQUEST: "SEND_MAILING_REQUEST",
  SEND_MAILING_SUCCESS: "SEND_MAILING_SUCCESS",
  SEND_MAILING_FAILURE: "SEND_MAILING_FAILURE",

  GET_MAILING_REQUEST: "GET_MAILING_REQUEST",
  GET_MAILING_SUCCESS: "GET_MAILING_SUCCESS",
  GET_MAILING_FAILURE: "GET_MAILING_FAILURE",

  GET_BY_ID_MAILING_REQUEST: "GET_BY_ID_MAILING_REQUEST",
  GET_BY_ID_MAILING_SUCCESS: "GET_BY_ID_MAILING_SUCCESS",
  GET_BY_ID_MAILING_FAILURE: "GET_BY_ID_MAILING_FAILURE",

  ADD_MAILING_REQUEST: "ADD_MAILING_REQUEST",
  ADD_MAILING_SUCCESS: "ADD_MAILING_SUCCESS",
  ADD_MAILING_FAILURE: "ADD_MAILING_FAILURE",

  SET_CLOSE_ALERT: "SET_CLOSE_ALERT",

};

export const addressConstants = {
  GET_ADDRESS_BY_ID_REQUEST: "GET_ADDRESS_BY_ID_REQUEST",
  GET_ADDRESS_BY_ID_SUCCESS: "GET_ADDRESS_BY_ID_SUCCESS",
  GET_ADDRESS_BY_ID_FAILURE: "GET_ADDRESS_BY_ID_FAILURE",

  SET_CLOSE_ADDRESS_ALERT: "SET_CLOSE_ADDRESS_ALERT",

};
export const categoryConstants = {
  UPDATE_CATEGORY_VISIBLE_BY_ID_REQUEST: "UPDATE_CATEGORY_VISIBLE_BY_ID_REQUEST",
  UPDATE_CATEGORY_VISIBLE_BY_ID_SUCCESS: "UPDATE_CATEGORY_VISIBLE_BY_ID_SUCCESS",
  UPDATE_CATEGORY_VISIBLE_BY_ID_FAILURE: "UPDATE_CATEGORY_VISIBLE_BY_ID_FAILURE",
  SET_CURRENT_CATEGORY: "SET_CURRENT_CATEGORY",
  SET_VISIBLE_HIDDEN_CATEGORIES: "SET_VISIBLE_HIDDEN_CATEGORIES",

  SET_CLOSE_CATEGORY_ALERT: "SET_CLOSE_CATEGORY_ALERT",

  UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_REQUEST: "UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_REQUEST",
  UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_SUCCESS: "UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_SUCCESS",
  UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_FAILURE: "UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_FAILURE",

  GET_ALL_CATEGORIES_REQUEST: "GET_ALL_CATEGORIES_REQUEST",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAILURE: "GET_ALL_CATEGORIES_FAILURE",

  ADD_NEW_CATEGORY_REQUEST: "ADD_NEW_CATEGORY_REQUEST",
  ADD_NEW_CATEGORY_SUCCESS: "ADD_NEW_CATEGORY_SUCCESS",
  ADD_NEW_CATEGORY_FAILURE: "ADD_NEW_CATEGORY_FAILURE",

  UPDATE_CATEGORIES_REQUEST: "UPDATE_CATEGORIES_REQUEST",
  UPDATE_CATEGORIES_SUCCESS: "UPDATE_CATEGORIES_SUCCESS",
  UPDATE_CATEGORIES_FAILURE: "UPDATE_CATEGORIES_FAILURE",

  DELETE_CATEGORIES_REQUEST: "DELETE_CATEGORIES_REQUEST",
  DELETE_CATEGORIES_SUCCESS: "DELETE_CATEGORIES_SUCCESS",
  DELETE_CATEGORIES_FAILURE: "DELETE_CATEGORIES_FAILURE",

  GET_CATEGORY_DETAILS_BY_ID_REQUEST: "GET_CATEGORY_DETAILS_BY_ID_REQUEST",
  GET_CATEGORY_DETAILS_BY_ID_SUCCESS: "GET_CATEGORY_DETAILS_BY_ID_SUCCESS",
  GET_CATEGORY_DETAILS_BY_ID_FAILURE: "GET_CATEGORY_DETAILS_BY_ID_FAILURE",
};

export const productConstants = {
  SET_PRODUCT_DETAILS: "SET_PRODUCT_DETAILS",

  UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_REQUEST: "UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_REQUEST",
  UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_SUCCESS: "UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_SUCCESS",
  UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_FAILURE: "UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_FAILURE",

  REMOVE_PRODUCT_MATERIALS_BY_ID_REQUEST: "REMOVE_PRODUCT_MATERIALS_BY_ID_REQUEST",
  REMOVE_PRODUCT_MATERIALS_BY_ID_SUCCESS: "REMOVE_PRODUCT_MATERIALS_BY_ID_SUCCESS",
  REMOVE_PRODUCT_MATERIALS_BY_ID_FAILURE: "REMOVE_PRODUCT_MATERIALS_BY_ID_FAILURE",

  REMOVE_PRODUCT_BLUEPRINTS_IDS_REQUEST: "REMOVE_PRODUCT_BLUEPRINTS_IDS_REQUEST",
  REMOVE_PRODUCT_BLUEPRINTS_IDS_SUCCESS: "REMOVE_PRODUCT_BLUEPRINTS_IDS_SUCCESS",
  REMOVE_PRODUCT_BLUEPRINTS_IDS_FAILURE: "REMOVE_PRODUCT_BLUEPRINTS_IDS_FAILURE",

  UPDATE_MAIN_IMAGE_BY_ID_REQUEST: "UPDATE_MAIN_IMAGE_BY_ID_REQUEST",
  UPDATE_MAIN_IMAGE_BY_ID_SUCCESS: "UPDATE_MAIN_IMAGE_BY_ID_SUCCESS",
  UPDATE_MAIN_IMAGE_BY_ID_FAILURE: "UPDATE_MAIN_IMAGE_BY_ID_FAILURE",

  TOGGLE_TYPE_IMG_REQUEST: "TOGGLE_TYPE_IMG_REQUEST",
  TOGGLE_TYPE_IMG_SUCCESS: "TOGGLE_TYPE_IMG_SUCCESS",
  TOGGLE_TYPE_IMG_FAILURE: "TOGGLE_TYPE_IMG_FAILURE",

  ADD_BLUEPRINTS_BY_ID_REQUEST: "ADD_BLUEPRINTS_BY_ID_REQUEST",
  ADD_BLUEPRINTS_BY_ID_SUCCESS: "ADD_BLUEPRINTS_BY_ID_SUCCESS",
  ADD_BLUEPRINTS_BY_ID_FAILURE: "ADD_BLUEPRINTS_BY_ID_FAILURE",

  RESET_TOTAL_AMOUNT_BY_ID_REQUEST: "RESET_TOTAL_AMOUNT_BY_ID_REQUEST",
  RESET_TOTAL_AMOUNT_BY_ID_SUCCESS: "RESET_TOTAL_AMOUNT_BY_ID_SUCCESS",
  RESET_TOTAL_AMOUNT_BY_ID_FAILURE: "RESET_TOTAL_AMOUNT_BY_ID_FAILURE",

  EDIT_PRODUCT_MATERIALS_BY_ID_REQUEST: "EDIT_PRODUCT_MATERIALS_BY_ID_REQUEST",
  EDIT_PRODUCT_MATERIALS_BY_ID_SUCCESS: "EDIT_PRODUCT_MATERIALS_BY_ID_SUCCESS",
  EDIT_PRODUCT_MATERIALS_BY_ID_FAILURE: "EDIT_PRODUCT_MATERIALS_BY_ID_FAILURE",

  ADD_PRODUCT_MATERIALS_BY_ID_REQUEST: "ADD_PRODUCT_MATERIALS_BY_ID_REQUEST",
  ADD_PRODUCT_MATERIALS_BY_ID_SUCCESS: "ADD_PRODUCT_MATERIALS_BY_ID_SUCCESS",
  ADD_PRODUCT_MATERIALS_BY_ID_FAILURE: "ADD_PRODUCT_MATERIALS_BY_ID_FAILURE",

  ADD_UPDATED_PICTURES_STATUS_BY_ID_REQUEST: "ADD_UPDATED_PICTURES_STATUS_BY_ID_REQUEST",
  ADD_UPDATED_PICTURES_STATUS_BY_ID_SUCCESS: "ADD_UPDATED_PICTURES_STATUS_BY_ID_SUCCESS",
  ADD_UPDATED_PICTURES_STATUS_BY_ID_FAILURE: "ADD_UPDATED_PICTURES_STATUS_BY_ID_FAILURE",

  TOGGLE_VISIBLE_IMG_REQUEST: "TOGGLE_VISIBLE_IMG_REQUEST",
  TOGGLE_VISIBLE_IMG_SUCCESS: "TOGGLE_VISIBLE_IMG_SUCCESS",
  TOGGLE_VISIBLE_IMG_FAILURE: "TOGGLE_VISIBLE_IMG_FAILURE",

  UPDATE_STATUS_PICTURES_REQUEST: "UPDATE_STATUS_PICTURES_REQUEST",
  UPDATE_STATUS_PICTURES_SUCCESS: "UPDATE_STATUS_PICTURES_SUCCESS",
  UPDATE_STATUS_PICTURES_FAILURE: "UPDATE_STATUS_PICTURES_FAILURE",

  CREATE_QR_CODE_BY_ID_REQUEST: "CREATE_QR_CODE_BY_ID_REQUEST",
  CREATE_QR_CODE_BY_ID_SUCCESS: "CREATE_QR_CODE_BY_ID_SUCCESS",
  CREATE_QR_CODE_BY_ID_FAILURE: "CREATE_QR_CODE_BY_ID_FAILURE",

  ADD_SAMPLE_PHOTO_REQUEST: "ADD_SAMPLE_PHOTO_REQUEST",
  ADD_SAMPLE_PHOTO_SUCCESS: "ADD_SAMPLE_PHOTO_SUCCESS",
  ADD_SAMPLE_PHOTO_FAILURE: "ADD_SAMPLE_PHOTO_FAILURE",

  GET_PRODUCT_BY_ID_REQUEST: "GET_PRODUCT_BY_ID_REQUEST",
  GET_PRODUCT_BY_ID_SUCCESS: "GET_PRODUCT_BY_ID_SUCCESS",
  GET_PRODUCT_BY_ID_FAILURE: "GET_PRODUCT_BY_ID_FAILURE",

  EDIT_PRODUCT_CATEGORY_REQUEST: "EDIT_PRODUCT_CATEGORY_REQUEST",
  EDIT_PRODUCT_CATEGORY_SUCCESS: "EDIT_PRODUCT_CATEGORY_SUCCESS",
  EDIT_PRODUCT_CATEGORY_FAILURE: "EDIT_PRODUCT_CATEGORY_FAILURE",

  REMOVE_PRODUCT_PICTURES_IDS_REQUEST: "REMOVE_PRODUCT_PICTURES_IDS_REQUEST",
  REMOVE_PRODUCT_PICTURES_IDS_SUCCESS: "REMOVE_PRODUCT_PICTURES_IDS_SUCCESS",
  REMOVE_PRODUCT_PICTURES_IDS_FAILURE: "REMOVE_PRODUCT_PICTURES_IDS_FAILURE",

  UPDATE_PRODUCT_NEW_BY_ID_REQUEST: "UPDATE_PRODUCT_NEW_BY_ID_REQUEST",
  UPDATE_PRODUCT_NEW_BY_ID_SUCCESS: "UPDATE_PRODUCT_NEW_BY_ID_SUCCESS",
  UPDATE_PRODUCT_NEW_BY_ID_FAILURE: "UPDATE_PRODUCT_NEW_BY_ID_FAILURE",

  UPDATE_ALL_PRODUCT_REQUEST: "UPDATE_ALL_PRODUCT_REQUEST",
  UPDATE_ALL_PRODUCT_SUCCESS: "UPDATE_ALL_PRODUCT_SUCCESS",
  UPDATE_ALL_PRODUCT_FAILURE: "UPDATE_ALL_PRODUCT_FAILURE",

  UPDATE_PRODUCT_MATERIAL_BY_ID: "UPDATE_PRODUCT_MATERIAL_BY_ID",

  CLOSE_ALERT_TO_PRODUCT_SUCCESS: "CLOSE_ALERT_TO_PRODUCT_SUCCESS",
  UPDATE_CATEGORY_NAME: "UPDATE_CATEGORY_NAME",
  UPDATE_PRODUCT_VISIBLE_BY_ID: "UPDATE_PRODUCT_VISIBLE_BY_ID",
  UPDATE_PRODUCT_PRICE_VISIBLE_BY_ID: "UPDATE_PRODUCT_PRICE_VISIBLE_BY_ID",

  GET_PRODUCTS_BY_SLUG_REQUEST: "GET_PRODUCTS_BY_SLUG_REQUEST",
  GET_PRODUCTS_BY_SLUG_SUCCESS: "GET_PRODUCTS_BY_SLUG_SUCCESS",
  GET_PRODUCTS_BY_SLUG_FAILURE: "GET_PRODUCTS_BY_SLUG_FAILURE",

  GET_ALL_PRODUCTS_REQUEST: "GET_ALL_PRODUCTS_REQUEST",
  GET_ALL_PRODUCTS_SUCCESS: "GET_ALL_PRODUCTS_SUCCESS",
  GET_ALL_PRODUCTS_FAILURE: "GET_ALL_PRODUCTS_FAILURE",

  DELETE_PRODUCT_BY_ID_REQUEST: "DELETE_PRODUCT_BY_ID_REQUEST",
  DELETE_PRODUCT_BY_ID_SUCCESS: "DELETE_PRODUCT_BY_ID_SUCCESS",
  DELETE_PRODUCT_BY_ID_FAILURE: "DELETE_PRODUCT_BY_ID_FAILURE",

  ADD_PRODUCT_REQUEST: "ADD_PRODUCT_REQUEST",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_REQUEST",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",
  SET_VIEW: "SET_VIEW",
  SET_SORT: "SET_SORT",
  SET_SHOW_ALERT: "SET_SHOW_ALERT",
  SET_ACTIVE_CATEGORY: "SET_ACTIVE_CATEGORY",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_PAGE_SIZE: "SET_PAGE_SIZE",


  SEARCH_PRODUCTS_REQUEST: "SEARCH_PRODUCTS_REQUEST",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAILURE: "SEARCH_PRODUCTS_FAILURE",

  UPDATE_PRODUCT_BY_ID_SUCCESS: "UPDATE_PRODUCT_BY_ID_SUCCESS",
  SET_CURRENT_CATEGORY: "SET_CURRENT_CATEGORY"
};
export const portfolioConstants = {
  GET_ALL_PORTFOLIO_REQUEST: "GET_ALL_PORTFOLIO_REQUEST",
  GET_ALL_PORTFOLIO_SUCCESS: "GET_ALL_PORTFOLIO_SUCCESS",
  GET_ALL_PORTFOLIO_FAILURE: "GET_ALL_PORTFOLIO_FAILURE",

  DELETE_PORTFOLIO_BY_ID_REQUEST: "DELETE_PORTFOLIO_BY_ID_REQUEST",
  DELETE_PORTFOLIO_BY_ID_SUCCESS: "DELETE_PORTFOLIO_BY_ID_SUCCESS",
  DELETE_PORTFOLIO_BY_ID_FAILURE: "DELETE_PORTFOLIO_BY_ID_FAILURE",

  ADD_PORTFOLIO_REQUEST: "ADD_PORTFOLIO_REQUEST",
  ADD_PORTFOLIO_SUCCESS: "ADD_PORTFOLIO_REQUEST",
  ADD_PORTFOLIO_FAILURE: "ADD_PORTFOLIO_FAILURE",
  SET_VIEW: "SET_VIEW",

  SEARCH_PORTFOLIO_REQUEST: "SEARCH_PORTFOLIO_REQUEST",
  SEARCH_PORTFOLIO_SUCCESS: "SEARCH_PORTFOLIO_SUCCESS",
  SEARCH_PORTFOLIO_FAILURE: "SEARCH_PORTFOLIO_FAILURE",

  UPDATE_PORTFOLIO_BY_ID_SUCCESS: "UPDATE_PORTFOLIO_BY_ID_SUCCESS"
};

export const initialDataConstants = {
  GET_ALL_INITIAL_DATA_REQUEST: "GET_ALL_INITIAL_DATA_REQUEST",
  GET_ALL_INITIAL_DATA_SUCCESS: "GET_ALL_INITIAL_DATA_SUCCESS",
  GET_ALL_INITIAL_DATA_FAILURE: "GET_ALL_INITIAL_DATA_FAILURE",
};

export const pageConstants = {
  CREATE_PAGE_REQUEST: "CREATE_PAGE_REQUEST",
  CREATE_PAGE_SUCCESS: "CREATE_PAGE_SUCCESS",
  CREATE_PAGE_FAILURE: "CREATE_PAGE_FAILURE",
};

export const orderConstants = {
  ALERT_CLOSE_ORDER_ADDITIONAL_FIELD: "ALERT_CLOSE_ORDER_ADDITIONAL_FIELD",

  UPDATE_ORDER_PACKAGE_TRACKING_REQUEST: "UPDATE_ORDER_PACKAGE_TRACKING_REQUEST",
  UPDATE_ORDER_PACKAGE_TRACKING_SUCCESS: "UPDATE_ORDER_PACKAGE_TRACKING_SUCCESS",
  UPDATE_ORDER_PACKAGE_TRACKING_FAILURE: "UPDATE_ORDER_PACKAGE_TRACKING_FAILURE",

  SEARCH_ORDERS_BY_ORDER_NUMBER_REQUEST: "SEARCH_ORDERS_BY_ORDER_NUMBER_REQUEST",
  SEARCH_ORDERS_BY_ORDER_NUMBER_SUCCESS: "SEARCH_ORDERS_BY_ORDER_NUMBER_SUCCESS",
  SEARCH_ORDERS_BY_ORDER_NUMBER_FAILURE: "SEARCH_ORDERS_BY_ORDER_NUMBER_FAILURE",


  UPDATE_ORDER_DELIVERY_ID_REQUEST: "UPDATE_ORDER_DELIVERY_ID_REQUEST",
  UPDATE_ORDER_DELIVERY_ID_SUCCESS: "UPDATE_ORDER_DELIVERY_ID_SUCCESS",
  UPDATE_ORDER_DELIVERY_ID_FAILURE: "UPDATE_ORDER_DELIVERY_ID_FAILURE",

  UPDATE_CANCELLATION_ORDER_STATUS_REQUEST: "UPDATE_CANCELLATION_ORDER_STATUS_REQUEST",
  UPDATE_CANCELLATION_ORDER_STATUS_SUCCESS: "UPDATE_CANCELLATION_ORDER_STATUS_SUCCESS",
  UPDATE_CANCELLATION_ORDER_STATUS_FAILURE: "UPDATE_CANCELLATION_ORDER_STATUS_FAILURE",

  ADD_ORDER_PRODUCTS_BU_ID_REQUEST: "ADD_ORDER_PRODUCTS_BU_ID_REQUEST",
  ADD_ORDER_PRODUCTS_BU_ID_SUCCESS: "ADD_ORDER_PRODUCTS_BU_ID_SUCCESS",
  ADD_ORDER_PRODUCTS_BU_ID_FAILURE: "ADD_ORDER_PRODUCTS_BU_ID_FAILURE",

  UPDATE_ORDER_VISIBLE_ADMIN_REQUEST: "UPDATE_ORDER_VISIBLE_ADMIN_REQUEST",
  UPDATE_ORDER_VISIBLE_ADMIN_SUCCESS: "UPDATE_ORDER_VISIBLE_ADMIN_SUCCESS",
  UPDATE_ORDER_VISIBLE_ADMIN_FAILURE: "UPDATE_ORDER_VISIBLE_ADMIN_FAILURE",

  UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_REQUEST: "UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_REQUEST",
  UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_SUCCESS: "UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_SUCCESS",
  UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_FAILURE: "UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_FAILURE",

  UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_REQUEST: "UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_REQUEST",
  UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_SUCCESS: "UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_SUCCESS",
  UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_FAILURE: "UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_FAILURE",

  GET_ORDER_BY_ID_REQUEST: "GET_ORDER_BY_ID_REQUEST",
  GET_ORDER_BY_ID_SUCCESS: "GET_ORDER_BY_ID_SUCCESS",
  GET_ORDER_BY_ID_FAILURE: "GET_ORDER_BY_ID_FAILURE",

  DELETE_ORDER_ADDITIONAL_BY_ID_REQUEST: "DELETE_ORDER_ADDITIONAL_BY_ID_REQUEST",
  DELETE_ORDER_ADDITIONAL_BY_ID_SUCCESS: "DELETE_ORDER_ADDITIONAL_BY_ID_SUCCESS",
  DELETE_ORDER_ADDITIONAL_BY_ID_FAILURE: "DELETE_ORDER_ADDITIONAL_BY_ID_FAILURE",


  DELETE_ORDER_PRODUCT_BY_ID_REQUEST: "DELETE_ORDER_PRODUCT_BY_ID_REQUEST",
  DELETE_ORDER_PRODUCT_BY_ID_SUCCESS: "DELETE_ORDER_PRODUCT_BY_ID_SUCCESS",
  DELETE_ORDER_PRODUCT_BY_ID_FAILURE: "DELETE_ORDER_PRODUCT_BY_ID_FAILURE",

  DELETE_ORDER_BY_ID_REQUEST: "DELETE_ORDER_BY_ID_REQUEST",
  DELETE_ORDER_BY_ID_SUCCESS: "DELETE_ORDER_BY_ID_SUCCESS",
  DELETE_ORDER_BY_ID_FAILURE: "DELETE_ORDER_BY_ID_FAILURE",

  GET_CUSTOMER_ORDER_REQUEST: "GET_CUSTOMER_ORDER_REQUEST",
  GET_CUSTOMER_ORDER_SUCCESS: "GET_CUSTOMER_ORDER_SUCCESS",
  GET_CUSTOMER_ORDER_FAILURE: "GET_CUSTOMER_ORDER_FAILURE",

  UPDATE_CUSTOMER_ORDER_REQUEST: "UPDATE_CUSTOMER_ORDER_REQUEST",
  UPDATE_CUSTOMER_ORDER_SUCCESS: "UPDATE_CUSTOMER_ORDER_SUCCESS",
  UPDATE_CUSTOMER_ORDER_FAILURE: "UPDATE_CUSTOMER_ORDER_FAILURE",

  UPDATE_ORDER_ADDITIONAL_FIELD_REQUEST: "UPDATE_ORDER_ADDITIONAL_FIELD_REQUEST",
  UPDATE_ORDER_ADDITIONAL_FIELD_SUCCESS: "UPDATE_ORDER_ADDITIONAL_FIELD_SUCCESS",
  UPDATE_ORDER_ADDITIONAL_FIELD_FAILURE: "UPDATE_ORDER_ADDITIONAL_FIELD_FAILURE",

  UPDATE_ORDER_ADDITIONAL_BY_ID_REQUEST: "UPDATE_ORDER_ADDITIONAL_BY_ID_REQUEST",
  UPDATE_ORDER_ADDITIONAL_BY_ID_SUCCESS: "UPDATE_ORDER_ADDITIONAL_BY_ID_SUCCESS",
  UPDATE_ORDER_ADDITIONAL_BY_ID_FAILURE: "UPDATE_ORDER_ADDITIONAL_BY_ID_FAILURE",

  UPDATE_ORDER_TOTAL_AMOUNT_REQUEST: "UPDATE_ORDER_TOTAL_AMOUNT_REQUEST",
  UPDATE_ORDER_TOTAL_AMOUNT_SUCCESS: "UPDATE_ORDER_TOTAL_AMOUNT_SUCCESS",
  UPDATE_ORDER_TOTAL_AMOUNT_FAILURE: "UPDATE_ORDER_TOTAL_AMOUNT_FAILURE",
};



export const featuredProductItemConstants = {
  CLOSE_ALERT: "CLOSE_ALERT",

  UPDATE_FEATURED_PRODUCT_ORDER_REQUEST: "UPDATE_FEATURED_PRODUCT_ORDER_REQUEST",
  UPDATE_FEATURED_PRODUCT_ORDER_SUCCESS: "UPDATE_FEATURED_PRODUCT_ORDER_SUCCESS",
  UPDATE_FEATURED_PRODUCT_ORDER_FAILURE: "UPDATE_FEATURED_PRODUCT_ORDER_FAILURE",

  REMOVE_PRODUCT_BY_IDS_REQUEST: "REMOVE_PRODUCT_BY_IDS_REQUEST",
  REMOVE_PRODUCT_BY_IDS_SUCCESS: "REMOVE_PRODUCT_BY_IDS_SUCCESS",
  REMOVE_PRODUCT_BY_IDS_FAILURE: "REMOVE_PRODUCT_BY_IDS_FAILURE",

  REMOVE_PRODUCT_BY_ID_REQUEST: "REMOVE_PRODUCT_BY_ID_REQUEST",
  REMOVE_PRODUCT_BY_ID_SUCCESS: "REMOVE_PRODUCT_BY_ID_SUCCESS",
  REMOVE_PRODUCT_BY_ID_FAILURE: "REMOVE_PRODUCT_BY_ID_FAILURE",

  GET_GALLERY_BY_ID_REQUEST: "GET_GALLERY_BY_ID_REQUEST",
  GET_GALLERY_BY_ID_SUCCESS: "GET_GALLERY_BY_ID_SUCCESS",
  GET_GALLERY_BY_ID_FAILURE: "GET_GALLERY_BY_ID_FAILURE",

  REMOVE_PICTURES_IDS_REQUEST: "REMOVE_PICTURES_IDS_REQUEST",
  REMOVE_PICTURES_IDS_SUCCESS: "REMOVE_PICTURES_IDS_SUCCESS",
  REMOVE_PICTURES_IDS_FAILURE: "REMOVE_PICTURES_IDS_FAILURE",

  ADD_SAMPLE_PHOTO_REQUEST: "ADD_SAMPLE_PHOTO_REQUEST",
  ADD_SAMPLE_PHOTO_SUCCESS: "ADD_SAMPLE_PHOTO_SUCCESS",
  ADD_SAMPLE_PHOTO_FAILURE: "ADD_SAMPLE_PHOTO_FAILURE",

  ADD_NEW_PRODUCT_REQUEST: "ADD_NEW_PRODUCT_REQUEST",
  ADD_NEW_PRODUCT_SUCCESS: "ADD_NEW_PRODUCT_SUCCESS",
  ADD_NEW_PRODUCT_FAILURE: "ADD_NEW_PRODUCT_FAILURE",

  CREATE_REQUEST: "CREATE_REQUEST",
  CREATE_SUCCESS: "CREATE_SUCCESS",
  CREATE_FAILURE: "CREATE_FAILURE",

  GET_ALL_ITEMS_REQUEST: "GET_ALL_ITEMS_REQUEST",
  GET_ALL_ITEMS_SUCCESS: "GET_ALL_ITEMS_SUCCESS",
  GET_ALL_ITEMS_FAILURE: "GET_ALL_ITEMS_FAILURE",

  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

  DELETE_BY_ID_REQUEST: "DELETE_BY_ID_REQUEST",
  DELETE_BY_ID_SUCCESS: "DELETE_BY_ID_SUCCESS",
  DELETE_BY_ID_FAILURE: "DELETE_BY_ID_FAILURE",

  UPDATE_VISIBLE_BY_ID_REQUEST: "UPDATE_VISIBLE_BY_ID_REQUEST",
  UPDATE_VISIBLE_BY_ID_SUCCESS: "UPDATE_VISIBLE_BY_ID_SUCCESS",
  UPDATE_VISIBLE_BY_ID_FAILURE: "UPDATE_VISIBLE_BY_ID_FAILURE",
}

export const galleryImageConstants = {
  GET_GALLERY_BY_ID_REQUEST: "GET_GALLERY_BY_ID_REQUEST",
  GET_GALLERY_BY_ID_SUCCESS: "GET_GALLERY_BY_ID_SUCCESS",
  GET_GALLERY_BY_ID_FAILURE: "GET_GALLERY_BY_ID_FAILURE",

  REMOVE_GALLERY_PICTURES_IDS_REQUEST: "REMOVE_GALLERY_PICTURES_IDS_REQUEST",
  REMOVE_GALLERY_PICTURES_IDS_SUCCESS: "REMOVE_GALLERY_PICTURES_IDS_SUCCESS",
  REMOVE_GALLERY_PICTURES_IDS_FAILURE: "REMOVE_GALLERY_PICTURES_IDS_FAILURE",

  ADD_SAMPLE_PHOTO_REQUEST: "ADD_SAMPLE_PHOTO_REQUEST",
  ADD_SAMPLE_PHOTO_SUCCESS: "ADD_SAMPLE_PHOTO_SUCCESS",
  ADD_SAMPLE_PHOTO_FAILURE: "ADD_SAMPLE_PHOTO_FAILURE",

  CREATE_GALLERY_REQUEST: "CREATE_GALLERY_REQUEST",
  CREATE_GALLERY_SUCCESS: "CREATE_GALLERY_SUCCESS",
  CREATE_GALLERY_FAILURE: "CREATE_GALLERY_FAILURE",

  GET_ALL_GALLERY_REQUEST: "GET_ALL_GALLERY_REQUEST",
  GET_ALL_GALLERY_SUCCESS: "GET_ALL_GALLERY_SUCCESS",
  GET_ALL_GALLERY_FAILURE: "GET_ALL_GALLERY_FAILURE",

  DELETE_GALLERY_BY_ID_REQUEST: "DELETE_GALLERY_BY_ID_REQUEST",
  DELETE_GALLERY_BY_ID_SUCCESS: "DELETE_GALLERY_BY_ID_SUCCESS",
  DELETE_GALLERY_BY_ID_FAILURE: "DELETE_GALLERY_BY_ID_FAILURE",
}
export const applicationCallMeConstants = {
  CLOSE_ALERT: "CLOSE_ALERT",
  GET_ALL_APPLICATION_CALL_ME_REQUEST: "GET_ALL_APPLICATION_CALL_ME_REQUEST",
  GET_ALL_APPLICATION_CALL_ME_SUCCESS: "GET_ALL_APPLICATION_CALL_ME_SUCCESS",
  GET_ALL_APPLICATION_CALL_ME_FAILURE: "GET_ALL_APPLICATION_CALL_ME_FAILURE",

  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",

  DELETE_MANY_REQUEST: "DELETE_MANY_REQUEST",
  DELETE_MANY_SUCCESS: "DELETE_MANY_SUCCESS",
  DELETE_MANY_FAILURE: "DELETE_MANY_FAILURE",

  DELETE_APPLICATION_CALL_ME_BY_ID_SUCCESS: "DELETE_APPLICATION_CALL_ME_BY_ID_SUCCESS",
}
export const applicationChangeProfileConstants = {
  CLOSE_ALERT: "CLOSE_ALERT",
  GET_ALL_APPLICATION_CHANGE_PROFILE_REQUEST: "GET_ALL_APPLICATION_CHANGE_PROFILE_REQUEST",
  GET_ALL_APPLICATION_CHANGE_PROFILE_SUCCESS: "GET_ALL_APPLICATION_CHANGE_PROFILE_SUCCESS",
  GET_ALL_APPLICATION_CHANGE_PROFILE_FAILURE: "GET_ALL_APPLICATION_CHANGE_PROFILE_FAILURE",

  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

  DELETE_MANY_REQUEST: "DELETE_MANY_REQUEST",
  DELETE_MANY_SUCCESS: "DELETE_MANY_SUCCESS",
  DELETE_MANY_FAILURE: "DELETE_MANY_FAILURE",

  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",
  DELETE_APPLICATION_CHANGE_PROFILE_BY_ID_SUCCESS: "DELETE_APPLICATION_CHANGE_PROFILE_BY_ID_SUCCESS",
}


export const applicationConstants = {
  CLOSE_ALERT: "CLOSE_ALERT",
  GET_ALL_APPLICATION_REQUEST: "GET_ALL_APPLICATION_REQUEST",
  GET_ALL_APPLICATION_SUCCESS: "GET_ALL_APPLICATION_SUCCESS",
  GET_ALL_APPLICATION_FAILURE: "GET_ALL_APPLICATION_FAILURE",

  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",

  DELETE_MANY_REQUEST: "DELETE_MANY_REQUEST",
  DELETE_MANY_SUCCESS: "DELETE_MANY_SUCCESS",
  DELETE_MANY_FAILURE: "DELETE_MANY_FAILURE",

  DELETE_APPLICATION_BY_ID_SUCCESS: "DELETE_APPLICATION_BY_ID_SUCCESS",
}


export const headerConstants = {
  GET_HEADER_REQUEST: "GET_HEADER_REQUEST",
  GET_HEADER_SUCCESS: "GET_HEADER_SUCCESS",
  GET_HEADER_FAILURE: "GET_HEADER_FAILURE",

  ADD_HEADER_REQUEST: "ADD_HEADER_REQUEST",
  ADD_HEADER_SUCCESS: "ADD_HEADER_SUCCESS",
  ADD_HEADER_FAILURE: "ADD_HEADER_FAILURE",

  SET_STATUS_REQUEST: "SET_STATUS_REQUEST",
  SET_STATUS_SUCCESS: "SET_STATUS_SUCCESS",
  SET_STATUS_FAILURE: "SET_STATUS_FAILURE",

  DELETE_HEADER_SUCCESS: "DELETE_HEADER_SUCCESS",

  UPDATE_HEADER_SUCCESS: "UPDATE_HEADER_SUCCESS"
}

export const manufactureConstants = {
  GET_MANUFACTURE_REQUEST: "GET_MANUFACTURE_REQUEST",
  GET_MANUFACTURE_SUCCESS: "GET_MANUFACTURE_SUCCESS",
  GET_MANUFACTURE_FAILURE: "GET_MANUFACTURE_FAILURE",

  ADD_MANUFACTURE_REQUEST: "ADD_MANUFACTURE_REQUEST",
  ADD_MANUFACTURE_SUCCESS: "ADD_MANUFACTURE_SUCCESS",
  ADD_MANUFACTURE_FAILURE: "ADD_MANUFACTURE_FAILURE",

  DELETE_MANUFACTURE_REQUEST: "DELETE_MANUFACTURE_REQUEST",
  DELETE_MANUFACTURE_SUCCESS: "DELETE_MANUFACTURE_SUCCESS",
  DELETE_MANUFACTURE_FAILURE: "DELETE_MANUFACTURE_FAILURE",

  UPDATE_MANUFACTURE_SUCCESS: "UPDATE_MANUFACTURE_SUCCESS"

}


export const promotionConstants = {
  GET_PROMOTION_REQUEST: "GET_PROMOTION_REQUEST",
  GET_PROMOTION_SUCCESS: "GET_PROMOTION_SUCCESS",
  GET_PROMOTION_FAILURE: "GET_PROMOTION_FAILURE",

  ADD_PROMOTION_REQUEST: "ADD_PROMOTION_REQUEST",
  ADD_PROMOTION_SUCCESS: "ADD_PROMOTION_SUCCESS",
  ADD_PROMOTION_FAILURE: "ADD_PROMOTION_FAILURE",

  DELETE_PROMOTION_REQUEST: "DELETE_PROMOTION_REQUEST",
  DELETE_PROMOTION_SUCCESS: "DELETE_PROMOTION_SUCCESS",
  DELETE_PROMOTION_FAILURE: "DELETE_PROMOTION_FAILURE",

  UPDATE_PROMOTION_SUCCESS: "UPDATE_PROMOTION_SUCCESS"
}

export const mainPageConstants = {
  ADD_MAIN_PAGE_REQUEST: "ADD_MAIN_PAGE_REQUEST",
  ADD_MAIN_PAGE_SUCCESS: "ADD_MAIN_PAGE_SUCCESS",
  ADD_MAIN_PAGE_FAILURE: "ADD_MAIN_PAGE_FAILURE",

  DELETE_MAIN_PAGE_REQUEST: "DELETE_MAIN_PAGE_REQUEST",
  DELETE_MAIN_PAGE_SUCCESS: "DELETE_MAIN_PAGE_SUCCESS",
  DELETE_MAIN_PAGE_FAILURE: "DELETE_MAIN_PAGE_FAILURE",

  UPDATE_MAIN_PAGE_REQUEST: "UPDATE_MAIN_PAGE_REQUEST",
  UPDATE_MAIN_PAGE_SUCCESS: "UPDATE_MAIN_PAGE_SUCCESS",
  UPDATE_MAIN_PAGE_FAILURE: "UPDATE_MAIN_PAGE_FAILURE",
}
export const productListLinkConstants = {
  GET_PRODUCT_LIST_LINK_REQUEST: "GET_PRODUCT_LIST_LINK_REQUEST",
  GET_PRODUCT_LIST_LINK_SUCCESS: "GET_PRODUCT_LIST_LINK_SUCCESS",
  GET_PRODUCT_LIST_LINK_FAILURE: "GET_PRODUCT_LIST_LINK_FAILURE",

  GET_BY_ID_PRODUCT_LIST_LINK_REQUEST: "GET_BY_ID_PRODUCT_LIST_LINK_REQUEST",
  GET_BY_ID_PRODUCT_LIST_LINK_SUCCESS: "GET_BY_ID_PRODUCT_LIST_LINK_SUCCESS",
  GET_BY_ID_PRODUCT_LIST_LINK_FAILURE: "GET_BY_ID_PRODUCT_LIST_LINK_FAILURE",

  ADD_PRODUCT_LIST_LINK_REQUEST: "ADD_PRODUCT_LIST_LINK_REQUEST",
  ADD_PRODUCT_LIST_LINK_SUCCESS: "ADD_PRODUCT_LIST_LINK_SUCCESS",
  ADD_PRODUCT_LIST_LINK_FAILURE: "ADD_PRODUCT_LIST_LINK_FAILURE",

  DELETE_PRODUCT_LIST_LINK_REQUEST: "DELETE_PRODUCT_LIST_LINK_REQUEST",
  DELETE_PRODUCT_LIST_LINK_SUCCESS: "DELETE_PRODUCT_LIST_LINK_SUCCESS",
  DELETE_PRODUCT_LIST_LINK_FAILURE: "DELETE_PRODUCT_LIST_LINK_FAILURE",

  UPDATE_PRODUCT_LIST_LINK_REQUEST: "UPDATE_PRODUCT_LIST_LINK_REQUEST",
  UPDATE_PRODUCT_LIST_LINK_SUCCESS: "UPDATE_PRODUCT_LIST_LINK_SUCCESS",
  UPDATE_PRODUCT_LIST_LINK_FAILURE: "UPDATE_PRODUCT_LIST_LINK_FAILURE",

  SET_CLOSE_ALERT: "SET_CLOSE_ALERT"
}

export const siteCategoryConstants = {
  GET_SITE_CATEGORIES_REQUEST: "GET_SITE_CATEGORIES_REQUEST",
  GET_SITE_CATEGORIES_SUCCESS: "GET_SITE_CATEGORIES_SUCCESS",
  GET_SITE_CATEGORIES_FAILURE: "GET_SITE_CATEGORIES_FAILURE",

  GET_BY_ID_SITE_CATEGORY_REQUEST: "GET_BY_ID_SITE_CATEGORY_REQUEST",
  GET_BY_ID_SITE_CATEGORY_SUCCESS: "GET_BY_ID_SITE_CATEGORY_SUCCESS",
  GET_BY_ID_SITE_CATEGORY_FAILURE: "GET_BY_ID_SITE_CATEGORY_FAILURE",

  ADD_SITE_CATEGORY_REQUEST: "ADD_SITE_CATEGORY_REQUEST",
  ADD_SITE_CATEGORY_SUCCESS: "ADD_SITE_CATEGORY_SUCCESS",
  ADD_SITE_CATEGORY_FAILURE: "ADD_SITE_CATEGORY_FAILURE",

  DELETE_SITE_CATEGORY_REQUEST: "DELETE_SITE_CATEGORY_REQUEST",
  DELETE_SITE_CATEGORY_SUCCESS: "DELETE_SITE_CATEGORY_SUCCESS",
  DELETE_SITE_CATEGORY_FAILURE: "DELETE_SITE_CATEGORY_FAILURE",

  UPDATE_SITE_CATEGORY_REQUEST: "UPDATE_SITE_CATEGORY_REQUEST",
  UPDATE_SITE_CATEGORY_SUCCESS: "UPDATE_SITE_CATEGORY_SUCCESS",
  UPDATE_SITE_CATEGORY_FAILURE: "UPDATE_SITE_CATEGORY_FAILURE",

  SET_CLOSE_ALERT: "SET_CLOSE_ALERT"
}

export const productMaterialConstants = {
  GET_PRODUCT_MATERIAL_REQUEST: "GET_PRODUCT_MATERIAL_REQUEST",
  GET_PRODUCT_MATERIAL_SUCCESS: "GET_PRODUCT_MATERIAL_SUCCESS",
  GET_PRODUCT_MATERIAL_FAILURE: "GET_PRODUCT_MATERIAL_FAILURE",

  GET_BY_ID_PRODUCT_MATERIAL_REQUEST: "GET_BY_ID_PRODUCT_MATERIAL_REQUEST",
  GET_BY_ID_PRODUCT_MATERIAL_SUCCESS: "GET_BY_ID_PRODUCT_MATERIAL_SUCCESS",
  GET_BY_ID_PRODUCT_MATERIAL_FAILURE: "GET_BY_ID_PRODUCT_MATERIAL_FAILURE",

  ADD_PRODUCT_MATERIAL_REQUEST: "ADD_PRODUCT_MATERIAL_REQUEST",
  ADD_PRODUCT_MATERIAL_SUCCESS: "ADD_PRODUCT_MATERIAL_SUCCESS",
  ADD_PRODUCT_MATERIAL_FAILURE: "ADD_PRODUCT_MATERIAL_FAILURE",

  DELETE_PRODUCT_MATERIAL_REQUEST: "DELETE_PRODUCT_MATERIAL_REQUEST",
  DELETE_PRODUCT_MATERIAL_SUCCESS: "DELETE_PRODUCT_MATERIAL_SUCCESS",
  DELETE_PRODUCT_MATERIAL_FAILURE: "DELETE_PRODUCT_MATERIAL_FAILURE",

  UPDATE_PRODUCT_MATERIAL_REQUEST: "UPDATE_PRODUCT_MATERIAL_REQUEST",
  UPDATE_PRODUCT_MATERIAL_SUCCESS: "UPDATE_PRODUCT_MATERIAL_SUCCESS",
  UPDATE_PRODUCT_MATERIAL_FAILURE: "UPDATE_PRODUCT_MATERIAL_FAILURE",

  SET_CLOSE_ALERT: "SET_CLOSE_ALERT"
}


export const mainImageConstants = {
  GET_MAIN_IMAGE_REQUEST: "GET_MAIN_IMAGE_REQUEST",
  GET_MAIN_IMAGE_SUCCESS: "GET_MAIN_IMAGE_SUCCESS",
  GET_MAIN_IMAGE_FAILURE: "GET_MAIN_IMAGE_FAILURE",

  ADD_MAIN_IMAGE_REQUEST: "ADD_MAIN_IMAGE_REQUEST",
  ADD_MAIN_IMAGE_SUCCESS: "ADD_MAIN_IMAGE_SUCCESS",
  ADD_MAIN_IMAGE_FAILURE: "ADD_MAIN_IMAGE_FAILURE",

  DELETE_MAIN_IMAGE_REQUEST: "DELETE_MAIN_IMAGE_REQUEST",
  DELETE_MAIN_IMAGE_SUCCESS: "DELETE_MAIN_IMAGE_SUCCESS",
  DELETE_MAIN_IMAGE_FAILURE: "DELETE_MAIN_IMAGE_FAILURE",

  UPDATE_MAIN_IMAGE_REQUEST: "UPDATE_MAIN_IMAGE_REQUEST",
  UPDATE_MAIN_IMAGE_SUCCESS: "UPDATE_MAIN_IMAGE_SUCCESS",
  UPDATE_MAIN_IMAGE_FAILURE: "UPDATE_MAIN_IMAGE_FAILURE",
}

export const comeToUsConstants = {
  GET_COME_TO_US_REQUEST: "GET_COME_TO_US_REQUEST",
  GET_COME_TO_US_SUCCESS: "GET_COME_TO_US_SUCCESS",
  GET_COME_TO_US_FAILURE: "GET_COME_TO_US_FAILURE",

  ADD_COME_TO_US_REQUEST: "ADD_COME_TO_US_REQUEST",
  ADD_COME_TO_US_SUCCESS: "ADD_COME_TO_US_SUCCESS",
  ADD_COME_TO_US_FAILURE: "ADD_COME_TO_US_FAILURE",

  UPDATE_COME_TO_US_REQUEST: "UPDATE_COME_TO_US_REQUEST",
  UPDATE_COME_TO_US_SUCCESS: "UPDATE_COME_TO_US_SUCCESS",
  UPDATE_COME_TO_US_FAILURE: "UPDATE_COME_TO_US_FAILURE",

  DELETE_COME_TO_US_REQUEST: "DELETE_COME_TO_US_REQUEST",
  DELETE_COME_TO_US_SUCCESS: "DELETE_COME_TO_US_SUCCESS",
  DELETE_COME_TO_US_FAILURE: "DELETE_COME_TO_US_FAILURE",
}

export const weWorkOnlineConstants = {
  GET_WE_WORK_ONLINE_REQUEST: "GET_WE_WORK_ONLINE_REQUEST",
  GET_WE_WORK_ONLINE_SUCCESS: "GET_WE_WORK_ONLINE_SUCCESS",
  GET_WE_WORK_ONLINE_FAILURE: "GET_WE_WORK_ONLINE_FAILURE",

  ADD_WE_WORK_ONLINE_REQUEST: "ADD_WE_WORK_ONLINE_REQUEST",
  ADD_WE_WORK_ONLINE_SUCCESS: "ADD_WE_WORK_ONLINE_SUCCESS",
  ADD_WE_WORK_ONLINE_FAILURE: "ADD_WE_WORK_ONLINE_FAILURE",

  UPDATE_WE_WORK_ONLINE_REQUEST: "UPDATE_WE_WORK_ONLINE_REQUEST",
  UPDATE_WE_WORK_ONLINE_SUCCESS: "UPDATE_WE_WORK_ONLINE_SUCCESS",
  UPDATE_WE_WORK_ONLINE_FAILURE: "UPDATE_WE_WORK_ONLINE_FAILURE",

  DELETE_WE_WORK_ONLINE_REQUEST: "DELETE_WE_WORK_ONLINE_REQUEST",
  DELETE_WE_WORK_ONLINE_SUCCESS: "DELETE_WE_WORK_ONLINE_SUCCESS",
  DELETE_WE_WORK_ONLINE_FAILURE: "DELETE_WE_WORK_ONLINE_FAILURE",
}


export const chooseMemorialPhotosConstants = {
  GET_CHOOSE_MEMORIAL_PHOTOS_REQUEST: "GET_CHOOSE_MEMORIAL_PHOTOS_REQUEST",
  GET_CHOOSE_MEMORIAL_PHOTOS_SUCCESS: "GET_CHOOSE_MEMORIAL_PHOTOS_SUCCESS",
  GET_CHOOSE_MEMORIAL_PHOTOS_FAILURE: "GET_CHOOSE_MEMORIAL_PHOTOS_FAILURE",

  ADD_CHOOSE_MEMORIAL_PHOTOS_REQUEST: "ADD_CHOOSE_MEMORIAL_PHOTOS_REQUEST",
  ADD_CHOOSE_MEMORIAL_PHOTOS_SUCCESS: "ADD_CHOOSE_MEMORIAL_PHOTOS_SUCCESS",
  ADD_CHOOSE_MEMORIAL_PHOTOS_FAILURE: "ADD_CHOOSE_MEMORIAL_PHOTOS_FAILURE",

  UPDATE_CHOOSE_MEMORIAL_PHOTOS_REQUEST: "UPDATE_CHOOSE_MEMORIAL_PHOTOS_REQUEST",
  UPDATE_CHOOSE_MEMORIAL_PHOTOS_SUCCESS: "UPDATE_CHOOSE_MEMORIAL_PHOTOS_SUCCESS",
  UPDATE_CHOOSE_MEMORIAL_PHOTOS_FAILURE: "UPDATE_CHOOSE_MEMORIAL_PHOTOS_FAILURE",

  DELETE_CHOOSE_MEMORIAL_PHOTOS_REQUEST: "DELETE_CHOOSE_MEMORIAL_PHOTOS_REQUEST",
  DELETE_CHOOSE_MEMORIAL_PHOTOS_SUCCESS: "DELETE_CHOOSE_MEMORIAL_PHOTOS_SUCCESS",
  DELETE_CHOOSE_MEMORIAL_PHOTOS_FAILURE: "DELETE_CHOOSE_MEMORIAL_PHOTOS_FAILURE",
}

export const engravingConstants = {
  GET_ENGRAVING_REQUEST: "GET_ENGRAVING_REQUEST",
  GET_ENGRAVING_SUCCESS: "GET_ENGRAVING_SUCCESS",
  GET_ENGRAVING_FAILURE: "GET_ENGRAVING_FAILURE",

  ADD_ENGRAVING_REQUEST: "ADD_ENGRAVING_REQUEST",
  ADD_ENGRAVING_SUCCESS: "ADD_ENGRAVING_SUCCESS",
  ADD_ENGRAVING_FAILURE: "ADD_ENGRAVING_FAILURE",

  UPDATE_ENGRAVING_REQUEST: "UPDATE_ENGRAVING_REQUEST",
  UPDATE_ENGRAVING_SUCCESS: "UPDATE_ENGRAVING_SUCCESS",
  UPDATE_ENGRAVING_FAILURE: "UPDATE_ENGRAVING_FAILURE",

  DELETE_ENGRAVING_REQUEST: "DELETE_ENGRAVING_REQUEST",
  DELETE_ENGRAVING_SUCCESS: "DELETE_ENGRAVING_SUCCESS",
  DELETE_ENGRAVING_FAILURE: "DELETE_ENGRAVING_FAILURE",
}

export const contactConstants = {
  GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAILURE: "GET_CONTACT_FAILURE",

  ADD_CONTACT_REQUEST: "ADD_CONTACT_REQUEST",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_FAILURE: "ADD_CONTACT_FAILURE",

  UPDATE_CONTACT_REQUEST: "UPDATE_CONTACT_REQUEST",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAILURE: "UPDATE_CONTACT_FAILURE",

  DELETE_CONTACT_REQUEST: "DELETE_CONTACT_REQUEST",
  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAILURE: "DELETE_CONTACT_FAILURE",
}

export const warrantyConstants = {
  GET_WARRANTY_REQUEST: "GET_WARRANTY_REQUEST",
  GET_WARRANTY_SUCCESS: "GET_WARRANTY_SUCCESS",
  GET_WARRANTY_FAILURE: "GET_WARRANTY_FAILURE",

  ADD_WARRANTY_REQUEST: "ADD_WARRANTY_REQUEST",
  ADD_WARRANTY_SUCCESS: "ADD_WARRANTY_SUCCESS",
  ADD_WARRANTY_FAILURE: "ADD_WARRANTY_FAILURE",

  UPDATE_WARRANTY_REQUEST: "UPDATE_WARRANTY_REQUEST",
  UPDATE_WARRANTY_SUCCESS: "UPDATE_WARRANTY_SUCCESS",
  UPDATE_WARRANTY_FAILURE: "UPDATE_WARRANTY_FAILURE",

  DELETE_WARRANTY_REQUEST: "DELETE_WARRANTY_REQUEST",
  DELETE_WARRANTY_SUCCESS: "DELETE_WARRANTY_SUCCESS",
  DELETE_WARRANTY_FAILURE: "DELETE_WARRANTY_FAILURE",
}


export const graniteMaterialConstants = {
  GET_GRANITE_MATERIAL_REQUEST: "GET_GRANITE_MATERIAL_REQUEST",
  GET_GRANITE_MATERIAL_SUCCESS: "GET_GRANITE_MATERIAL_SUCCESS",
  GET_GRANITE_MATERIAL_FAILURE: "GET_GRANITE_MATERIAL_FAILURE",

  ADD_GRANITE_MATERIAL_REQUEST: "ADD_GRANITE_MATERIAL_REQUEST",
  ADD_GRANITE_MATERIAL_SUCCESS: "ADD_GRANITE_MATERIAL_SUCCESS",
  ADD_GRANITE_MATERIAL_FAILURE: "ADD_GRANITE_MATERIAL_FAILURE",

  UPDATE_GRANITE_MATERIAL_REQUEST: "UPDATE_GRANITE_MATERIAL_REQUEST",
  UPDATE_GRANITE_MATERIAL_SUCCESS: "UPDATE_GRANITE_MATERIAL_SUCCESS",
  UPDATE_GRANITE_MATERIAL_FAILURE: "UPDATE_GRANITE_MATERIAL_FAILURE",

  DELETE_GRANITE_MATERIAL_REQUEST: "DELETE_GRANITE_MATERIAL_REQUEST",
  DELETE_GRANITE_MATERIAL_SUCCESS: "DELETE_GRANITE_MATERIAL_SUCCESS",
  DELETE_GRANITE_MATERIAL_FAILURE: "DELETE_GRANITE_MATERIAL_FAILURE",
}

export const paymentListInstructionConstants = {
  GET_PAYMENT_LIST_INSTRUCTION_REQUEST: "GET_PAYMENT_LIST_INSTRUCTION_REQUEST",
  GET_PAYMENT_LIST_INSTRUCTION_SUCCESS: "GET_PAYMENT_LIST_INSTRUCTION_SUCCESS",
  GET_PAYMENT_LIST_INSTRUCTION_FAILURE: "GET_PAYMENT_LIST_INSTRUCTION_FAILURE",

  ADD_PAYMENT_LIST_INSTRUCTION_REQUEST: "ADD_PAYMENT_LIST_INSTRUCTION_REQUEST",
  ADD_PAYMENT_LIST_INSTRUCTION_SUCCESS: "ADD_PAYMENT_LIST_INSTRUCTION_SUCCESS",
  ADD_PAYMENT_LIST_INSTRUCTION_FAILURE: "ADD_PAYMENT_LIST_INSTRUCTION_FAILURE",

  UPDATE_PAYMENT_LIST_INSTRUCTION_REQUEST: "UPDATE_PAYMENT_LIST_INSTRUCTION_REQUEST",
  UPDATE_PAYMENT_LIST_INSTRUCTION_SUCCESS: "UPDATE_PAYMENT_LIST_INSTRUCTION_SUCCESS",
  UPDATE_PAYMENT_LIST_INSTRUCTION_FAILURE: "UPDATE_PAYMENT_LIST_INSTRUCTION_FAILURE",

  DELETE_PAYMENT_LIST_INSTRUCTION_REQUEST: "DELETE_PAYMENT_LIST_INSTRUCTION_REQUEST",
  DELETE_PAYMENT_LIST_INSTRUCTION_SUCCESS: "DELETE_PAYMENT_LIST_INSTRUCTION_SUCCESS",
  DELETE_PAYMENT_LIST_INSTRUCTION_FAILURE: "DELETE_PAYMENT_LIST_INSTRUCTION_FAILURE",
}


export const catalogTitleConstants = {
  GET_CATALOG_TITLE_REQUEST: "GET_CATALOG_TITLE_REQUEST",
  GET_CATALOG_TITLE_SUCCESS: "GET_CATALOG_TITLE_SUCCESS",
  GET_CATALOG_TITLE_FAILURE: "GET_CATALOG_TITLE_FAILURE",

  ADD_CATALOG_TITLE_REQUEST: "ADD_CATALOG_TITLE_REQUEST",
  ADD_CATALOG_TITLE_SUCCESS: "ADD_CATALOG_TITLE_SUCCESS",
  ADD_CATALOG_TITLE_FAILURE: "ADD_CATALOG_TITLE_FAILURE",

  UPDATE_CATALOG_TITLE_REQUEST: "UPDATE_CATALOG_TITLE_REQUEST",
  UPDATE_CATALOG_TITLE_SUCCESS: "UPDATE_CATALOG_TITLE_SUCCESS",
  UPDATE_CATALOG_TITLE_FAILURE: "UPDATE_CATALOG_TITLE_FAILURE",

  DELETE_CATALOG_TITLE_REQUEST: "DELETE_CATALOG_TITLE_REQUEST",
  DELETE_CATALOG_TITLE_SUCCESS: "DELETE_CATALOG_TITLE_SUCCESS",
  DELETE_CATALOG_TITLE_FAILURE: "DELETE_CATALOG_TITLE_FAILURE",
}

export const costDeliveryConstants = {
  GET_COST_DELIVERY_REQUEST: "GET_COST_DELIVERY_REQUEST",
  GET_COST_DELIVERY_SUCCESS: "GET_COST_DELIVERY_SUCCESS",
  GET_COST_DELIVERY_FAILURE: "GET_COST_DELIVERY_FAILURE",

  ADD_COST_DELIVERY_REQUEST: "ADD_COST_DELIVERY_REQUEST",
  ADD_COST_DELIVERY_SUCCESS: "ADD_COST_DELIVERY_SUCCESS",
  ADD_COST_DELIVERY_FAILURE: "ADD_COST_DELIVERY_FAILURE",

  UPDATE_COST_DELIVERY_REQUEST: "UPDATE_COST_DELIVERY_REQUEST",
  UPDATE_COST_DELIVERY_SUCCESS: "UPDATE_COST_DELIVERY_SUCCESS",
  UPDATE_COST_DELIVERY_FAILURE: "UPDATE_COST_DELIVERY_FAILURE",

  DELETE_COST_DELIVERY_REQUEST: "DELETE_COST_DELIVERY_REQUEST",
  DELETE_COST_DELIVERY_SUCCESS: "DELETE_COST_DELIVERY_SUCCESS",
  DELETE_COST_DELIVERY_FAILURE: "DELETE_COST_DELIVERY_FAILURE",
}

export const blogConstants = {
  GET_BLOG_REQUEST: "GET_BLOG_REQUEST",
  GET_BLOG_SUCCESS: "GET_BLOG_SUCCESS",
  GET_BLOG_FAILURE: "GET_BLOG_FAILURE",

  ADD_BLOG_REQUEST: "ADD_BLOG_REQUEST",
  ADD_BLOG_SUCCESS: "ADD_BLOG_SUCCESS",
  ADD_BLOG_FAILURE: "ADD_BLOG_FAILURE",

  UPDATE_BLOG_REQUEST: "UPDATE_BLOG_REQUEST",
  UPDATE_BLOG_SUCCESS: "UPDATE_BLOG_SUCCESS",
  UPDATE_BLOG_FAILURE: "UPDATE_BLOG_FAILURE",

  DELETE_BLOG_REQUEST: "DELETE_BLOG_REQUEST",
  DELETE_BLOG_SUCCESS: "DELETE_BLOG_SUCCESS",
  DELETE_BLOG_FAILURE: "DELETE_BLOG_FAILURE",
  SET_VIEW: "SET_VIEW"
}

export const tombstoneCurbConstants = {
  GET_TOMBSTONE_CURB_REQUEST: "GET_TOMBSTONE_CURB_REQUEST",
  GET_TOMBSTONE_CURB_SUCCESS: "GET_TOMBSTONE_CURB_SUCCESS",
  GET_TOMBSTONE_CURB_FAILURE: "GET_TOMBSTONE_CURB_FAILURE",

  ADD_TOMBSTONE_CURB_REQUEST: "ADD_TOMBSTONE_CURB_REQUEST",
  ADD_TOMBSTONE_CURB_SUCCESS: "ADD_TOMBSTONE_CURB_SUCCESS",
  ADD_TOMBSTONE_CURB_FAILURE: "ADD_TOMBSTONE_CURB_FAILURE",

  UPDATE_TOMBSTONE_CURB_REQUEST: "UPDATE_TOMBSTONE_CURB_REQUEST",
  UPDATE_TOMBSTONE_CURB_SUCCESS: "UPDATE_TOMBSTONE_CURB_SUCCESS",
  UPDATE_TOMBSTONE_CURB_FAILURE: "UPDATE_TOMBSTONE_CURB_FAILURE",

  DELETE_TOMBSTONE_CURB_REQUEST: "DELETE_TOMBSTONE_CURB_REQUEST",
  DELETE_TOMBSTONE_CURB_SUCCESS: "DELETE_TOMBSTONE_CURB_SUCCESS",
  DELETE_TOMBSTONE_CURB_FAILURE: "DELETE_TOMBSTONE_CURB_FAILURE",
  SET_VIEW: "SET_VIEW"
}

export const footerConstants = {
  GET_FOOTER_REQUEST: "GET_FOOTER_REQUEST",
  GET_FOOTER_SUCCESS: "GET_FOOTER_SUCCESS",
  GET_FOOTER_FAILURE: "GET_FOOTER_FAILURE",

  ADD_FOOTER_REQUEST: "ADD_FOOTER_REQUEST",
  ADD_FOOTER_SUCCESS: "ADD_FOOTER_SUCCESS",
  ADD_FOOTER_FAILURE: "ADD_FOOTER_FAILURE",

  UPDATE_FOOTER_REQUEST: "UPDATE_FOOTER_REQUEST",
  UPDATE_FOOTER_SUCCESS: "UPDATE_FOOTER_SUCCESS",
  UPDATE_FOOTER_FAILURE: "UPDATE_FOOTER_FAILURE",

  DELETE_FOOTER_REQUEST: "DELETE_FOOTER_REQUEST",
  DELETE_FOOTER_SUCCESS: "DELETE_FOOTER_SUCCESS",
  DELETE_FOOTER_FAILURE: "DELETE_FOOTER_FAILURE",
}

export const ceramicsConstants = {
  GET_CERAMICS_REQUEST: "GET_CERAMICS_REQUEST",
  GET_CERAMICS_SUCCESS: "GET_CERAMICS_SUCCESS",
  GET_CERAMICS_FAILURE: "GET_CERAMICS_FAILURE",

  ADD_CERAMICS_REQUEST: "ADD_CERAMICS_REQUEST",
  ADD_CERAMICS_SUCCESS: "ADD_CERAMICS_SUCCESS",
  ADD_CERAMICS_FAILURE: "ADD_CERAMICS_FAILURE",

  UPDATE_CERAMICS_REQUEST: "UPDATE_CERAMICS_REQUEST",
  UPDATE_CERAMICS_SUCCESS: "UPDATE_CERAMICS_SUCCESS",
  UPDATE_CERAMICS_FAILURE: "UPDATE_CERAMICS_FAILURE",

  DELETE_CERAMICS_REQUEST: "DELETE_CERAMICS_REQUEST",
  DELETE_CERAMICS_SUCCESS: "DELETE_CERAMICS_SUCCESS",
  DELETE_CERAMICS_FAILURE: "DELETE_CERAMICS_FAILURE",
  SET_VIEW: "SET_VIEW"

}


